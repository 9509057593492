export default {
  building: (buildingId: string) => `/admin/buildings/${buildingId}`,
  buildingContacts: (buildingId: string) => `/admin/buildings/${buildingId}?tab=contacts`,
  buildingListings: (buildingId: string) => `/admin/buildings/${buildingId}?tab=listedSpaces`,
  buildingSearch: '/admin/buildings',
  companies: '/admin/companies',
  company: (id: string) => `/admin/companies/${id}`,
  companyCreate: '/admin/companies/create',
  landlordCreate: '/admin/landlords/new',
  landlordEdit: (id: string) => `/admin/landlords/${id}`,
  landlords: '/admin/landlords',
  listingPage: (id: string) => `/admin/listings/${id}`,
  listingSearch: '/admin/listings',
  root: '/admin',
  userPage: (id: string) => `/admin/users/${id}`,
  userSearch: '/admin/users',
  vtsProperty: (propertyId: string) => `/admin/vts_properties/${propertyId}`,
  vtsPropertySearch: '/admin/vts_properties',
  tourbookExportTool: '/admin/tourbook_export_tool',
} as const;
