import { Fragment } from 'react';
import * as React from 'react';
import BugsnagReactPlugin from '@bugsnag/plugin-react';
import config from './config';

const client = {};

const shouldBugsnag = typeof window !== 'undefined' && config?.BUGSNAG_API_KEY;
const hasFullStory = () =>
  typeof window !== 'undefined' && window.FS && window.FS.getCurrentSessionURL;

if (shouldBugsnag) {
  const Bugsnag = require('@bugsnag/js'); // eslint-disable-line global-require

  client.current = Bugsnag.start({
    apiKey: config.BUGSNAG_API_KEY,
    plugins: [new BugsnagReactPlugin(React)],
    onError(event) {
      if (hasFullStory()) {
        event.addMetadata('fullstory', {
          urlAtTime: window.FS.getCurrentSessionURL(true),
        });
      }
    },
  });
} else {
  client.current = {
    getPlugin: () => Fragment,
  };
}

export const setBugsnagUser = user => {
  if (!user) return;
  if (shouldBugsnag) {
    client.current.setUser(user.id, user.email, `${user.firstName} ${user.lastName}`);
  }
};

export default client.current;
