import { EVENTS, PARAMETERS, EVENT_TYPES } from '@root/tracking/constants';
import { TourbookListingAnalyticsInformation } from '@root/types';

export type SourceContent =
  | 'building video'
  | 'building page gallery'
  | 'space video'
  | 'lightbox gallery'
  | 'landlord video'
  | 'embedded video player'
  | 'space 3d tour'
  | 'embedded 3d tour'
  | 'tourbook listing gallery'
  | 'brand gallery'
  | 'spotlight tour'
  | 'listing preview'
  | string;

export type SourcePage = 'listing page' | 'building page' | 'brand page' | 'tourbook page' | string;

type VideoPlayedAction = {
  type: 'VIDEO_PLAYED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Video Interaction';
        sourceContent: SourceContent;
        sourcePage: SourcePage;
        action: 'play video';
        analyticsInformation?: TourbookListingAnalyticsInformation | undefined;
      };
    };
  };
};
export const videoPlayed = ({
  sourceContent,
  sourcePage,
  analyticsInformation,
}: {
  sourceContent: SourceContent;
  sourcePage: SourcePage;
  analyticsInformation?: TourbookListingAnalyticsInformation | undefined;
}): VideoPlayedAction => ({
  type: 'VIDEO_PLAYED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.videoInteraction,
        sourceContent,
        sourcePage,
        action: PARAMETERS.playVideo,
        ...analyticsInformation,
      },
    },
  },
});

type ThreeDTourPlayedAction = {
  type: '3D_TOUR_PLAYED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: '3D Tour Interaction';
        sourceContent: SourceContent;
        sourcePage: SourcePage;
        analyticsInformation?: TourbookListingAnalyticsInformation | undefined;
        action: 'play 3d tour';
      };
    };
  };
};

export const threeDTourPlayed = ({
  sourceContent,
  sourcePage,
  analyticsInformation,
}: {
  sourceContent: SourceContent;
  sourcePage: SourcePage;
  analyticsInformation?: TourbookListingAnalyticsInformation | undefined;
}): ThreeDTourPlayedAction => ({
  type: '3D_TOUR_PLAYED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.threeDTourInteraction,
        sourceContent,
        sourcePage,
        action: PARAMETERS.play3DTour,
        ...analyticsInformation,
      },
    },
  },
});

type PercentMap = {
  25: '25 percent played';
  50: '50 percent played';
  75: '75 percent played';
  100: '100 percent played';
};
const percentMap: PercentMap = {
  25: PARAMETERS.twentyFivePercentPlayed,
  50: PARAMETERS.fiftyPercentPlayed,
  75: PARAMETERS.seventyFivePercentPlayed,
  100: PARAMETERS.hundredPercentPlayed,
};
type PercentVideoPlayedAction = {
  type: 'PERCENT_OF_VIDEO_PLAYED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Video Interaction';
        sourceContent: SourceContent;
        sourcePage: SourcePage;
        action: PercentMap[keyof PercentMap];
      };
    };
  };
};

export const percentVideoPlayed = ({
  sourceContent,
  sourcePage,
  amount,
  analyticsInformation,
}: {
  sourceContent: SourceContent;
  sourcePage: SourcePage;
  amount: keyof PercentMap;
  analyticsInformation?: TourbookListingAnalyticsInformation | undefined;
}): PercentVideoPlayedAction => ({
  type: 'PERCENT_OF_VIDEO_PLAYED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.videoInteraction,
        sourceContent,
        sourcePage,
        action: percentMap[amount],
        ...analyticsInformation,
      },
    },
  },
});

type VideoImpressionAction = {
  type: 'VIDEO_IMPRESSION';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Video Interaction';
        sourceContent: SourceContent;
        sourcePage: SourcePage;
        action: 'impression';
      };
    };
  };
};
export const videoImpression = ({
  sourcePage,
  sourceContent,
}: {
  sourcePage: SourcePage;
  sourceContent: SourceContent;
}): VideoImpressionAction => ({
  type: 'VIDEO_IMPRESSION',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.videoInteraction,
        sourceContent,
        sourcePage,
        action: PARAMETERS.videoImpression,
      },
    },
  },
});

type ThreeDTourImpressionAction = {
  type: '3D_TOUR_IMPRESSION';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: '3D Tour Interaction';
        sourceContent: SourceContent;
        sourcePage: SourcePage;
        action: 'impression';
      };
    };
  };
};
export const threeDTourImpression = ({
  sourcePage,
  sourceContent,
}: {
  sourcePage: SourcePage;
  sourceContent: SourceContent;
}): ThreeDTourImpressionAction => ({
  type: '3D_TOUR_IMPRESSION',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.threeDTourInteraction,
        sourceContent,
        sourcePage,
        action: PARAMETERS.threeDTourImpression,
      },
    },
  },
});

export type Actions = VideoPlayedAction | PercentVideoPlayedAction | VideoImpressionAction;
