import { useRef, useState, useEffect } from 'react';
import renderingInServer from '@shared/renderingInServer';
import { TourbookListingAnalyticsInformation, Video } from '@root/types';
import { reduce } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  videoPlayed,
  percentVideoPlayed,
  SourcePage,
  SourceContent,
} from '@root/store/actions/videoAnalytics';
import useEnv from '@shared/useEnv';
import Bugsnag from '@bugsnag/js';

interface UseVideoPlayerArguments {
  autoplay: boolean;
  sourceContent: SourceContent;
  sourcePage: SourcePage;
  video: Video;
  fill: boolean;
  fluid: boolean;
  analyticsInformation?: TourbookListingAnalyticsInformation | undefined;
}

const useVideoPlayer = ({
  autoplay,
  sourcePage,
  sourceContent,
  video,
  fill,
  fluid,
  analyticsInformation,
}: UseVideoPlayerArguments) => {
  const { cloudinaryCloud, cloudinaryCdn } = useEnv();
  const videoTagRef = useRef<HTMLVideoElement | null>(null);
  const playerRef = useRef();
  const hasPlayEventFired = useRef<boolean>(false);

  const dispatch = useDispatch();
  const playEvent = () => {
    if (!hasPlayEventFired.current) {
      hasPlayEventFired.current = true;
      dispatch(
        videoPlayed({
          sourceContent,
          sourcePage,
          analyticsInformation,
        }),
      );
    }
  };

  const percentPlayedEvent = (amount: 25 | 50 | 75 | 100) => {
    dispatch(
      percentVideoPlayed({
        sourceContent,
        sourcePage,
        analyticsInformation,
        amount,
      }),
    );
  };

  const handleError = e => {
    // eslint-disable-next-line no-underscore-dangle
    const { message = '', statusCode = '' } = e?.Player?.videojs?.error_;
    Bugsnag.notify(
      `An error occured attempting to play a video. status code: ${statusCode} - ${message}`,
    );
    hasPlayEventFired.current = true;
  };

  const isServerSideRendered = renderingInServer();

  // Essentially there is a 3 step process to loading the video player:
  // 1 (optional), if server side rendering, do nothing, html video player stays as is
  // 2. load cloudinary video player script tag if its not loaded already
  // 3. use cloudinary video player to infuse the html video tag
  const [cloudinaryCoreLoaded, setCloudinaryCoreLoaded] = useState(
    () => !isServerSideRendered && !!window.cloudinary,
  );
  const [cloudinaryVideoLoaded, setCloudinaryVideoLoaded] = useState(
    () => !isServerSideRendered && !!window.cloudinary,
  );

  const [videoTagSetupWithCloudinary, setVideoTagSetupWithCloudinary] = useState(false);

  // steps 1 and 2, do nothing w/ SSR and load the cloudinary script if its not already
  useEffect(() => {
    if (isServerSideRendered) return; // skip in case of step 1
    // skip in case step 2 is done already
    if (cloudinaryCoreLoaded || cloudinaryVideoLoaded) return;

    // it is indeed annoying that the cloudinary video player is loaded this way, i agree :)
    // the guides, as of when this was written required it and didn't provide a npm script
    const cloudinaryCoreScript = document.createElement('script');
    const cloudinaryVideoPlayerScript = document.createElement('script');
    cloudinaryCoreScript.src =
      'https://unpkg.com/cloudinary-core@2.12.3/cloudinary-core-shrinkwrap.min.js';
    cloudinaryVideoPlayerScript.src =
      'https://unpkg.com/cloudinary-video-player@1.8.0/dist/cld-video-player.min.js';
    window.document.body.appendChild(cloudinaryCoreScript);
    window.document.body.appendChild(cloudinaryVideoPlayerScript);
    cloudinaryVideoPlayerScript.addEventListener('load', () => setCloudinaryVideoLoaded(true));
    cloudinaryCoreScript.addEventListener('load', () => setCloudinaryCoreLoaded(true));
  }, [isServerSideRendered, cloudinaryCoreLoaded, cloudinaryVideoLoaded]);

  // step 3
  useEffect(() => {
    if (!cloudinaryCoreLoaded || !cloudinaryVideoLoaded) return; // skip if step 2 isn't done
    if (videoTagSetupWithCloudinary) return; // skip if already set up

    const cloudinaryInstance = window.cloudinary.Cloudinary.new({
      cloud_name: cloudinaryCloud,
      secure_distribution: cloudinaryCdn,
      private_cdn: true,
      secure: true,
    });

    try {
      type Result = {
        hls?: Array<string | { [k: string]: string }>;
        mp4?: Array<string | { [k: string]: string }>;
      };
      const sourceTransformation: any = reduce(
        video.sourceTransformation,
        (result: Result, values, key) => ({
          ...result,
          [key]: values!.map(value => (Array.isArray(value) ? { [value[0]]: value[1] } : value)),
        }),
        {},
      );
      const player = cloudinaryInstance.videoPlayer(videoTagRef.current, {
        autoplay,
        controls: true,
        sourceTransformation,
        sourceTypes: video.sourceTypes,
        showLogo: false,
        fluid,
        fill,
        playedEventPercents: [25, 50, 75, 100],
        hideContextMenu: true,
        posterOptions: { transformation: { start_offset: video.posterStartOffset } },
      });
      player.source(video.cloudinaryId);
      player.on('percentsplayed', event => {
        percentPlayedEvent(event.eventData.percent);
      });
      player.on('play', playEvent);
      player.on('error', handleError);

      setVideoTagSetupWithCloudinary(true);
      playerRef.current = player;
    } catch (err) {
      // eslint-disable-next-line  no-console
      console.error('Cloudinary failed to instantiate the video player:', err);
    }
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cloudinaryCoreLoaded, cloudinaryVideoLoaded]);

  return {
    videoTagRef,
    player: playerRef.current,
  };
};

export default useVideoPlayer;
