import { Action } from '@root/types';
import {
  LISTING_SEARCH_MAP_MOVED,
  LISTING_SEARCH_MAP_RESET,
  LISTING_SEARCH_DRAW_START,
  LISTING_SEARCH_DRAW_END,
  LISTING_SEARCH_SET_DRAWN_POLYGONS,
  LISTING_SEARCH_POLYGON_COMPLETE,
} from '../entities';

export type MapState = {
  moved: boolean;
  isDrawing: boolean;
  drawnPolygons: any[];
};

export const defaultState = {
  moved: false,
  isDrawing: false,
  drawnPolygons: [],
};

export default function mapReducer(state: MapState = defaultState, action: Action) {
  switch (action.type) {
    case LISTING_SEARCH_MAP_MOVED: {
      return {
        ...state,
        moved: true,
      };
    }

    case LISTING_SEARCH_MAP_RESET: {
      return {
        ...state,
        moved: false,
      };
    }

    case LISTING_SEARCH_DRAW_START: {
      return {
        ...state,
        isDrawing: true,
      };
    }

    case LISTING_SEARCH_POLYGON_COMPLETE:
    case LISTING_SEARCH_DRAW_END: {
      return {
        ...state,
        isDrawing: false,
      };
    }

    case LISTING_SEARCH_SET_DRAWN_POLYGONS: {
      return {
        ...state,
        drawnPolygons: action.payload,
      };
    }

    default:
      return state;
  }
}
