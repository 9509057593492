import { stringify } from 'qs';

export default {
  root: '/',
  users: {
    edit: (accountSettingsTab: string) => `/users/account/${accountSettingsTab}`,
    account: '/users/account',
    inquirySettings: '/users/account?page=inquiry',
  },
  browseBuilding: (slug: string) => `/browse/listings/buildings/${slug}`,
  browseLocality: (localitySlug: string, abbreviatedRegion: string) =>
    `/browse/listings/${abbreviatedRegion}/${localitySlug}`,
  browseRegionList: '/browse/listings',
  browseRegion: (abbreviatedRegion: string) => `/browse/listings/${abbreviatedRegion}`,
  browseBuildingInCity: (city: string, abbreviatedRegion: string) =>
    `/browse/listings/${abbreviatedRegion}/${city}`,
  building: (buildingSlug: string) => `/building/${buildingSlug}`,
  buildingPreview: (buildingSlug: string) => `/building_previews/${buildingSlug}`,
  listing: (listingId: string, buildingSlug: string) => `/listing/${buildingSlug}/${listingId}`,
  listingPreview: (listingId: string) => `/listing_previews/${listingId}`,
  listingShare: (encodedData: string) => `/listing_shares/${encodedData}`,
  vtsMarketBuildingShare: (buildingSlug: string) => `/market_buildings/${buildingSlug}`,
  vtsMarketListingShare: (listingId: string) => `/market_listings/${listingId}`,
  login: '/login',
  logout: '/logout',
  vtsMarketLandlordShare: (landlordSlug: string) => `/market_landlords/${landlordSlug}`,
  landlordPreview: (landlordId: string) => `/landlord_previews/${landlordId}`,
  landlords: (landlordSlug: string) => `/landlords/${landlordSlug}`,
  signUp: (
    vtsUrl: string,
    // eslint-disable-next-line camelcase
    query: { utm_term?: string; utm_content?: string; anonymous_id?: string } = {},
  ) => {
    const queryString = stringify(
      { ...query, source: 'marketplace' },
      { addQueryPrefix: true, skipNulls: true },
    );

    return `${vtsUrl}/users/sign_up${queryString}`;
  },
  savedListings: '/saved/listings',
  savedSearch: (id: string) => `/saved_searches/${id}`,
  savedSearches: '/saved/searches',
  savedBuildings: '/saved/buildings',
  savedBuildingOwners: '/saved/building_owners',
  search: (marketSlug: string) => `/search/${marketSlug}`,
  tourbook: (tourbookId: string) => `/tourbooks/${tourbookId}`,
  tourbookAnalytics: (tourbookId: string) => `/tourbooks/${tourbookId}/analytics`,
  tourbookShare: (tourbookId: string) => `/tourbook_shares/${tourbookId}`,
  tourbookNewExternalListing: (tourbookId: string) =>
    `/tourbooks/${tourbookId}/external_listings/new`,
  tourbooks: '/tourbooks',
  tourbookEditExternalListing: (tourbookId: string, externalListingId: string) =>
    `/tourbooks/${tourbookId}/external_listings/${externalListingId}/edit`,
  waitlist: (
    vtsUrl: string,
    // eslint-disable-next-line camelcase
    query: { utm_term?: string; utm_content?: string; anonymous_id?: string } = {},
  ) => {
    const queryString = stringify(
      { ...query, source: 'marketplace' },
      { addQueryPrefix: true, skipNulls: true },
    );

    return `${vtsUrl}/waitlist${queryString}`;
  },
} as const;
