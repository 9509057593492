import { getCurrentPageType, getListingSearchPageLevelArguments } from '@store/selectors';
import { Page } from '@store/reducers/pageReducer';
import { StoreState } from '@root/types';
import { PARAMETERS, EVENT_TYPES } from '@root/tracking/constants';
import { isInIframe } from '@root/shared/iframeUtils';
import { page, track, identify } from '@root/tracking/analytics';

const eventTypeToAnalyticsFunction = {
  page,
  track,
  identify,
};

const isNotAnAnalyticsAction = action => !action.payload?.meta?.analytics;

const getListingSharePageLevelArguments = (state: StoreState) => {
  const listing = state.listings.listingShare;

  return {
    listingPreview: false,
    ...listing!.analyticsInformation,
  };
};

const getTourbookPageLevelArguments = state => {
  const tourbook = state.tourbooks.byId[state.page.pageArguments.id];
  if (!tourbook) return {};

  return {
    tourbookName: tourbook.name,
    tourbookId: tourbook.id,
    tourbookOwnerId: tourbook.owner.id,
    tourbookOwnerVTSId: tourbook.owner.vtsId,
    isShare: state.page.pageArguments.isShare,
    listingCount: tourbook.listings ? tourbook.listings.length : 0,
  };
};

const getPageLevelArguments = (state, skipPageInfo) => {
  if (skipPageInfo) return {};

  switch (state.page.type) {
    case 'LISTING_SHARE': {
      return getListingSharePageLevelArguments(state);
    }
    case 'LISTING_SEARCH': {
      return getListingSearchPageLevelArguments(state);
    }
    case 'VIDEO_EMBED':
    case 'LISTING':
    case 'LANDLORD':
    case 'BUILDING': {
      return state.page.pageArguments || {};
    }
    case 'TOURBOOK':
    case 'TOURBOOK_ANALYTICS': {
      return getTourbookPageLevelArguments(state);
    }
    default:
      return {};
  }
};

export const getMarketFriendlySourcePage = (sourcePage: string | null | undefined) => {
  if (!isInIframe()) return sourcePage;

  switch (sourcePage) {
    case PARAMETERS.manageTourbooksPage:
      return PARAMETERS.marketMyTourbooksPage;
    case PARAMETERS.tourbookExternalListingPage:
      return PARAMETERS.marketTourbookExternalListingPage;
    case PARAMETERS.tourbookPage:
      return PARAMETERS.marketTourbookPage;
    default:
      return sourcePage;
  }
};

const getMarketFriendlyEvent = (event: string, eventType: string) =>
  isInIframe() && eventType === EVENT_TYPES.page ? getMarketFriendlySourcePage(event) : event;

type InitialEntryUtmParams = {
  name: string;
  source: string;
  medium: string;
  term: string;
  content: string;
};

function getTrackingQueryParams(): InitialEntryUtmParams | null {
  if (!window) return null;

  const params = new URLSearchParams(window.location.search);

  const [name, source, medium, term, content] = [
    params.get('utm_campaign') || params.get('vts_campaign') || '',
    params.get('utm_source') || params.get('vts_source') || '',
    params.get('utm_medium') || params.get('vts_medium') || '',
    params.get('utm_term') || params.get('vts_term') || '',
    params.get('utm_content') || params.get('vts_content') || '',
  ];

  if (!name && !source && !medium && !term && !content) return null;
  return { name, source, medium, term, content };
}

// Remove the transition array logic when removing vts-trackable-parameters
const transitionArray = {
  BUILDING: ['LANDLORD', 'LISTING'],
  LANDLORD: ['BUILDING', 'LISTING'],
  LISTING: ['LANDLORD', 'BUILDING'],
  LISTING_SHARE: ['LANDLORD', 'BUILDING'],
  TOURBOOK: ['LISTING', 'LISTING_SHARE'],
};

function continueInitialUtmTracking(previousStatePage?: Page, currentStatePage?: Page): boolean {
  return (
    !previousStatePage ||
    previousStatePage === currentStatePage ||
    !!transitionArray[previousStatePage]?.includes(currentStatePage)
  );
}

// Remove this wrapper and export analyticsMiddleware from this file
// when removing ff vts-trackable-parameters
export const analyticsMiddlewareWrapper = (trackableVTSParameters?: boolean) => {
  return store => analyticsMiddleware(store, trackableVTSParameters);
};

// Remove the argument
const analyticsMiddleware = (store, trackableVTSParameters = false) => {
  // Use event queuing to ensure analytics events are sent after the user state is
  // determined (e.g. the user is known or will be anonymous for the session).
  // This queuing solution was added to address this ticket:
  // https://viewthespace.atlassian.net/browse/TRV-1730
  let queue: {
    analyticsFn: (event, args, opts) => void;
    event: any;
    args: any;
  }[] = [];

  // Include initial UTM params in future analytics events as long as specified criteria are met.
  // Move this inside of the next scope when removing vts-trackable-parameters.
  // This has to be initialized in the next action.
  let initialUtmParams: InitialEntryUtmParams | null = getTrackingQueryParams();

  return next => action => {
    if (trackableVTSParameters) {
      initialUtmParams = getTrackingQueryParams();
    }
    const previousStatePage = getCurrentPageType(store.getState());

    const nextResult = next(action);

    const currentStatePage = getCurrentPageType(store.getState());

    if (
      initialUtmParams &&
      !trackableVTSParameters &&
      !continueInitialUtmTracking(previousStatePage, currentStatePage)
    ) {
      initialUtmParams = null;
    }

    if (isNotAnAnalyticsAction(action)) return nextResult;

    if (action.payload.meta.analytics.forIdentification) {
      const { identifier, user } = action.payload.meta.analytics;

      if (user) {
        identify(identifier, user, {
          visualize: store.getState().ui.visualizeAnalytics,
          environment: store.getState().environment,
        });
      }

      queue.forEach(({ analyticsFn, event, args }) =>
        analyticsFn(event, args, {
          visualize: store.getState().ui.visualizeAnalytics,
          environment: store.getState().environment,
        }),
      );
      queue = [];
      return nextResult;
    }

    const { eventType, event, skipPageInfo, sourcePage, ...analyticsArguments } =
      action.payload.meta.analytics;
    const analyticsFn = eventTypeToAnalyticsFunction[eventType];

    const pageLevelArguments = getPageLevelArguments(store.getState(), skipPageInfo);
    const marketFriendlyEvent = getMarketFriendlyEvent(event, eventType);
    const marketFriendlySourcePage = getMarketFriendlySourcePage(sourcePage);
    const sourcePageArguments =
      sourcePage !== undefined ? { sourcePage: marketFriendlySourcePage } : {};

    const shouldEnqueueEvents =
      !store.getState().loadingStates?.currentUser /* user is already loaded, dont enqueue */ &&
      !window.skipAnalyticsEnqueuing; /* this is set so tests can skip enqueuing */
    if (shouldEnqueueEvents) {
      queue.push({
        analyticsFn,
        event: marketFriendlyEvent,
        args: {
          ...(initialUtmParams ? { initial_campaign: initialUtmParams } : {}),
          ...pageLevelArguments,
          ...analyticsArguments,
          ...sourcePageArguments,
        },
      });
    } else {
      analyticsFn(
        marketFriendlyEvent,
        {
          ...(initialUtmParams ? { initial_campaign: initialUtmParams } : {}),
          ...pageLevelArguments,
          ...analyticsArguments,
          ...sourcePageArguments,
        },
        {
          visualize: store.getState().ui.visualizeAnalytics,
          environment: store.getState().environment,
        },
      );
    }

    return nextResult;
  };
};

export default analyticsMiddleware;
