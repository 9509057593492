import {
  LISTING_SEARCH_LOADING,
  RECEIVE_CURRENT_USER,
  RECEIVE_NO_USER,
  TOURBOOKS_LOADED,
} from '../entities';

export type LoadedStoreState = {
  [entity: string]: boolean;
};

export default function loadingReducer(state: LoadedStoreState = {}, action) {
  switch (action.type) {
    case LISTING_SEARCH_LOADING: {
      const { loading } = action.payload;
      return {
        ...state,
        listingSearch: loading,
      };
    }

    case TOURBOOKS_LOADED: {
      return {
        ...state,
        tourbooks: true,
      };
    }
    case RECEIVE_CURRENT_USER:
    case RECEIVE_NO_USER: {
      return {
        ...state,
        currentUser: true,
      };
    }
    default:
      return state;
  }
}
