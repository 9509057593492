import { ConvertableMoneyPerPeriod, LocaleUpCase } from '@root/types';
import formatCurrency from './formatCurrency';
import { getPeriod } from './periods';

type args = {
  min: ConvertableMoneyPerPeriod | null;
  max: ConvertableMoneyPerPeriod | null;
  locale: LocaleUpCase;
  abbreviatedPeriod: boolean | undefined | null;
  options: Intl.NumberFormatOptions | undefined | null;
};

export default ({ min, max, locale, abbreviatedPeriod, options = {} }: args) => {
  if (!min && !max) return null;

  const minCurrencyWithUnits =
    min &&
    formatCurrency({
      magnitude: Math.round(min.magnitude!),
      currency: min.units.money,
      locale,
      options,
    });
  const maxCurrencyWithUnits =
    max &&
    formatCurrency({
      magnitude: Math.round(max.magnitude!),
      currency: max.units.money,
      locale,
      options,
    });

  const formattedTimePeriod = getPeriod(
    min?.units.period! || max?.units.period!,
    abbreviatedPeriod,
  );

  if (minCurrencyWithUnits && maxCurrencyWithUnits) {
    return `${minCurrencyWithUnits} - ${maxCurrencyWithUnits} / ${formattedTimePeriod}`;
  }

  return `${minCurrencyWithUnits || maxCurrencyWithUnits} / ${formattedTimePeriod}`;
};
