import { withLDProvider } from 'launchdarkly-react-client-sdk';

export default function configureLaunchDarkly(
  App,
  launchDarklyAllFlagsState,
  launchDarklyClientSideId,
  railsEnv,
) {
  const testOptions = {
    streaming: false,
    sendEvents: false,
    fetchGoals: false,
  };

  return withLDProvider({
    clientSideID: launchDarklyClientSideId,
    options: {
      ...(railsEnv === 'test' ? testOptions : {}),
      bootstrap: launchDarklyAllFlagsState,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  })(App);
}
