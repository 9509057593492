import numeral from 'numeral';
import { Area, ConvertableArea } from '@root/types';
import units from './units';

export default (value: ConvertableArea | Area | null | undefined) => {
  if (!value) return '0 sf';

  const { magnitude } = value;
  let unit;
  if ('type' in value) {
    unit = value.units.area;
  } else {
    unit = value.unit;
  }

  return `${numeral(magnitude).format()} ${units[unit]}`;
};
