import {
  Listing,
  SearchListing,
  BuildingMapPoint,
  Shape,
  PaginationResponse,
  Building,
  Landlord,
  Tourbook,
} from '@root/types';

type Arguments =
  | {
      entity: 'listing';
      data: { listings: Listing };
    }
  | {
      entity: 'searchResults';
      data: {
        listings: SearchListing[];
        buildings: BuildingMapPoint[];
        shapes: Shape[];
        pagination: PaginationResponse;
      };
    }
  | { entity: 'buildings'; data: Building }
  | {
      entity: 'listingShare';
      data: {
        listingShare: Listing;
      };
    }
  | {
      entity: 'landlord';
      data: Landlord;
    }
  | {
      entity: 'tourbook';
      data: Tourbook;
    };

// Listing Page
type ListingPageInitialLoadAction = {
  type: 'LISTING_PAGE_SERVER_SIDE_RENDER_INITIAL_LOAD';
  payload: Listing;
};
function setListingPageInitialLoad(listing: Listing): ListingPageInitialLoadAction {
  return {
    type: 'LISTING_PAGE_SERVER_SIDE_RENDER_INITIAL_LOAD',
    payload: listing,
  };
}

// Listing Search Page
type ListingSearchPageInitialLoadAction = {
  type: 'LISTING_SEARCH_PAGE_SERVER_SIDE_RENDER_INITIAL_LOAD';
  payload: {
    listings: SearchListing[];
    buildings: BuildingMapPoint[];
    shapes: Shape[];
    pagination: PaginationResponse;
  };
};
function setListingSearchPageInitialLoad({
  listings,
  buildings,
  shapes,
  pagination,
}: {
  listings: SearchListing[];
  buildings: BuildingMapPoint[];
  shapes: Shape[];
  pagination: PaginationResponse;
}): ListingSearchPageInitialLoadAction {
  return {
    type: 'LISTING_SEARCH_PAGE_SERVER_SIDE_RENDER_INITIAL_LOAD',
    payload: {
      listings,
      buildings,
      shapes,
      pagination,
    },
  };
}

// Building Page
type BuildingPageInitialLoadAction = {
  type: 'BUILDING_PAGE_SERVER_SIDE_RENDER_INITIAL_LOAD';
  payload: Building;
};
function setBuildingPageInitialLoad(building: Building): BuildingPageInitialLoadAction {
  return {
    type: 'BUILDING_PAGE_SERVER_SIDE_RENDER_INITIAL_LOAD',
    payload: building,
  };
}

// Listing Share Page
type ListingSharePageInitialLoadAction = {
  type: 'LISTING_SHARE_PAGE_SERVER_SIDE_RENDER_INITIAL_LOAD';
  payload: Listing;
};
function setListingSharePageInitialLoad(listing: Listing): ListingSharePageInitialLoadAction {
  return {
    type: 'LISTING_SHARE_PAGE_SERVER_SIDE_RENDER_INITIAL_LOAD',
    payload: listing,
  };
}

// Landlord Page
type LandlordPageInitialLoadAction = {
  type: 'LANDLORD_PAGE_SERVER_SIDE_RENDER_INITIAL_LOAD';
  payload: {
    data: Landlord;
  };
};
function setLandlordPageInitialLoad(landlord: Landlord): LandlordPageInitialLoadAction {
  return {
    type: 'LANDLORD_PAGE_SERVER_SIDE_RENDER_INITIAL_LOAD',
    payload: {
      data: landlord,
    },
  };
}

// Tourbook Page
type TourbookInitialLoadAction = {
  type: 'TOUR_BOOK_SERVER_SIDE_RENDER_INITIAL_LOAD';
  payload: {
    data: Tourbook;
  };
};
function setTourbookInitialLoad(tourbook: Tourbook): TourbookInitialLoadAction {
  return {
    type: 'TOUR_BOOK_SERVER_SIDE_RENDER_INITIAL_LOAD',
    payload: {
      data: tourbook,
    },
  };
}

function assertUnreachable(x: never): never {
  throw new Error(
    // eslint-disable-next-line max-len
    `Trying to server side render a page with an unknown entity (${x}). Add case to setServerSideRenderedData`,
  );
}

export type ServerSideRenderedActions =
  | ListingPageInitialLoadAction
  | ListingSearchPageInitialLoadAction
  | BuildingPageInitialLoadAction
  | ListingSharePageInitialLoadAction
  | LandlordPageInitialLoadAction
  | TourbookInitialLoadAction;

// eslint-disable-next-line consistent-return
export default function setServerSideRenderedData(args: Arguments): ServerSideRenderedActions {
  switch (args.entity) {
    case 'listing': {
      return setListingPageInitialLoad(args.data.listings);
    }
    case 'searchResults': {
      return setListingSearchPageInitialLoad(args.data);
    }
    case 'buildings': {
      return setBuildingPageInitialLoad(args.data);
    }
    case 'listingShare': {
      return setListingSharePageInitialLoad(args.data.listingShare);
    }
    case 'landlord': {
      return setLandlordPageInitialLoad(args.data);
    }
    case 'tourbook': {
      return setTourbookInitialLoad(args.data);
    }
    default: {
      const { entity } = args;
      // this should never happen, but if someone makes a
      // mistake on the ruby side, this will give them
      // an actionable error
      assertUnreachable(entity);
    }
  }
}
