import { CurrentUser } from '@root/types';

const initializePendoForKnownUser = function initializePendo(currentUser: CurrentUser) {
  if (!window || !window.pendo || !window.pendo.initialize) return;

  window.pendo.initialize({
    visitor: {
      id: currentUser.id,
      email: currentUser.email,
      role: currentUser.role,
      userMarket: currentUser.analyticsInformation.userMarket,
      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },
  });
};

const initializePendoForAnonymousUser = function initializePendo() {
  if (!window || !window.pendo || !window.pendo.initialize) return;

  window.pendo.initialize();
};

// eslint-disable-next-line import/prefer-default-export
export { initializePendoForKnownUser, initializePendoForAnonymousUser };
