import { Listing, SearchListing, Pagination, Action, LastInquiry } from '@root/types';
import normalize from '@shared/normalize';

export type ListingsStoreState = {
  byId: { [id: string]: Listing };
  searchResults: { [id: string]: SearchListing };
  pagination: Pagination | null;
  listingShare: Listing | null;
  listingIdsByIndex: { [index: number]: string };
  lastInquiryById: { [id: string]: LastInquiry };
};
const defaultState = {
  byId: {},
  searchResults: {},
  pagination: null,
  listingShare: null,
  listingIdsByIndex: {},
  lastInquiryById: {},
};

const groupIdsByIndex = (ids, { currentPage, pageSize }) =>
  ids.reduce((pageIdsList, id, indexInPage) => {
    const index = (currentPage - 1) * pageSize + indexInPage;
    return { ...pageIdsList, [index]: id };
  }, {});

export default function listingsReducer(state: ListingsStoreState = defaultState, action: Action) {
  switch (action.type) {
    case 'LISTING_SEARCH_PAGE_SERVER_SIDE_RENDER_INITIAL_LOAD': {
      const { listings, pagination } = action.payload;
      const ids = listings.map(listing => listing.id);

      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          ...normalize(listings),
        },
        pagination: {
          ...pagination,
          ids,
        },
      };
    }

    case 'LISTING_PAGE_SERVER_SIDE_RENDER_INITIAL_LOAD': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload,
        },
      };
    }
    case 'RECEIVE_FETCH_LISTING': {
      const { listing, lastInquiry } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [listing.id]: listing,
        },
        lastInquiryById: {
          ...state.lastInquiryById,
          [lastInquiry?.listingId]: lastInquiry,
        },
      };
    }
    case 'LISTING_SHARE_PAGE_SERVER_SIDE_RENDER_INITIAL_LOAD': {
      return {
        ...state,
        listingShare: action.payload,
      };
    }
    case 'RECEIVE_SEARCH_LISTINGS': {
      const { listings, pagination, inquiries } = action.payload;
      const ids = listings.map(listing => listing.id);
      const normalizedListings = normalize(listings);

      return {
        ...state,
        searchResults: { ...state.searchResults, ...normalizedListings },
        pagination: { ...pagination, ids },
        listingIdsByIndex: groupIdsByIndex(ids, pagination),
        lastInquiryById: {
          ...state.lastInquiryById,
          ...normalize(inquiries, { attr: 'listingId' }),
        },
      };
    }
    case 'RECEIVE_SEARCH_LISTINGS_FROM_LISTING_PREVIEW': {
      const { listings, pagination, inquiries } = action.payload;
      const ids = listings.map(listing => listing.id);
      const normalizedListings = normalize(listings);

      return {
        ...state,
        searchResults: { ...state.searchResults, ...normalizedListings },
        listingIdsByIndex: {
          ...state.listingIdsByIndex,
          ...groupIdsByIndex(ids, pagination),
        },
        lastInquiryById: {
          ...state.lastInquiryById,
          ...normalize(inquiries, { attr: 'listingId' }),
        },
      };
    }
    case 'RECEIVE_CREATED_INQUIRY': {
      return {
        ...state,
        lastInquiryById: {
          ...state.lastInquiryById,
          [action.payload.listingId]: action.payload,
        },
      };
    }

    default:
      return state;
  }
}
