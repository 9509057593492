import numeral from 'numeral';
import { MinMaxArea } from '@root/types';
import units from './units';

export default (value?: MinMaxArea | null) => {
  if (value) {
    const unit = value.unit ? units[value.unit] : 'sf';
    return `${numeral(value.min?.magnitude).format()} - ${numeral(
      value.max?.magnitude,
    ).format()} ${unit}`;
  }
  return null;
};
