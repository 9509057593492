/**
 * A configuration bundle injected into our application
 * by the server
 * @typedef {Object} ConfigBundle
 * @property {Object} BUGSNAG_API_KEY
 */

/**
 * @type {ConfigBundle}
 */
let config = {}; // eslint-disable-line import/no-mutable-exports

if (typeof window !== 'undefined') {
  config = window.config; // eslint-disable-line prefer-destructuring
  delete window.config;
}

export default config;
