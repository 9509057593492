import { isObject } from 'lodash';

function byAttr<T>(objects: T[], attr: string): { [key: string]: T } {
  return objects.reduce((agg, object) => ({ ...agg, [object[attr]]: object }), {});
}

function normalize<T>(
  data: T | T[],
  { attr }: { attr: string } = { attr: 'id' },
): Record<string, T> {
  switch (true) {
    case Array.isArray(data):
      return byAttr<T>(data as T[], attr);
    case isObject(data): {
      const identifier: string = data[attr];
      return { [identifier]: data as T };
    }
    default:
      return data as never;
  }
}

export default normalize;
