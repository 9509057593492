import { Action } from '@root/types';

const defaultState = null;
export type EnumsStoreState = string;

export default function environmentReducer(state: string | null = defaultState, action: Action) {
  switch (action.type) {
    case 'SET_ENVIRONMENT':
      return action.payload;
    default:
      return state;
  }
}
