import apiAdminRoutes from './apiAdmin';
import apiMarketplaceRoutes from './apiMarketplace';
import adminRoutes from './admin';
import marketplaceRoutes from './marketplace';

export default {
  api: {
    ...apiMarketplaceRoutes,
    admin: apiAdminRoutes,
  },
  admin: adminRoutes,
  ...marketplaceRoutes,
} as const;
