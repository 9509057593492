/* eslint-disable no-underscore-dangle */
import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import renderingInServer from '@shared/renderingInServer';
import actions from '@store/actions';
import { BackendEnums } from '@root/types';
import setServerSideRenderedData from './actions/setServerSideRenderedData';
import initializePendoMiddleware from './pendoMiddleware';
import { analyticsMiddlewareWrapper } from './analyticsMiddleware'; // Rename analyticsMiddlewareWrapper to analyticsMiddleware when removing vts-trackable-parameters
import {
  UIReducer,
  listingsReducer,
  buildingsReducer,
  currentUserReducer,
  shapesReducer,
  savedListingsReducer,
  loadedReducer,
  pageReducer,
  enumsReducer,
  savedSearchesReducer,
  savedBuildingOwnersReducer,
  mapReducer,
  tourbookReducer,
  environmentReducer,
} from './reducers';

export const reducers = combineReducers({
  // PLEASE PLEASE PLEASE KEEP THIS SORTED ALPHABETICALLY, this determines order in the dev tools
  buildings: buildingsReducer,
  enums: enumsReducer,
  environment: environmentReducer,
  currentUser: currentUserReducer,
  listings: listingsReducer,
  loadingStates: loadedReducer,
  map: mapReducer,
  page: pageReducer,
  savedBuildingOwners: savedBuildingOwnersReducer,
  savedListings: savedListingsReducer,
  savedSearches: savedSearchesReducer,
  shapes: shapesReducer,
  tourbooks: tourbookReducer,
  ui: UIReducer,
});
export type RootReducerState = ReturnType<typeof reducers>;

export default function configureStore(
  preloadedState = {},
  originalData: any = {},
  entity = null,
  enums: null | BackendEnums = null,
  environment: string = 'development',
  trackableVTSParameters: boolean = false, // Remove this prop with vts-trackable-parameters
) {
  const composeEnhancers = (function generateComposeEnhancers() {
    // eslint-disable-next-line dot-notation
    if (renderingInServer() || window['Cypress']) {
      return compose;
    }
    // eslint-disable-next-line dot-notation
    if (window?.['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']) {
      // eslint-disable-next-line dot-notation
      return window?.['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']({ trace: true });
    }
    return compose;
  })();
  let initialState = preloadedState as RootReducerState;

  const setEnvAction = actions.setEnvironment(environment);
  initialState = reducers(initialState, setEnvAction);

  if (entity) {
    const action = setServerSideRenderedData({ data: originalData, entity });
    initialState = reducers(initialState, action);
  }

  if (enums) {
    const enumAction = actions.setEnums(enums);
    initialState = reducers(initialState, enumAction);
  }

  // Rename the below line to analyticsMiddleware and remove the prop
  // trackableVTSParameters when removing vts-trackable-parameters
  return createStore(
    reducers,
    initialState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        analyticsMiddlewareWrapper(trackableVTSParameters),
        initializePendoMiddleware,
      ),
    ),
  );
}
