import { MessageType, ModalType, NotificationMessage } from '@root/types/Iframe';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

type QueryParameters = {
  [key: string]: string | null;
};
export const trackIfIsInIframe = (queryParameters: QueryParameters, vtsUrl: string): void => {
  if (queryParameters?.iframe === 'true') {
    window.isInIframe = true;
    window.vtsUrl = vtsUrl;
  }
};

export const isInIframe = (): boolean => {
  return !!window.isInIframe;
};

const messageHost = (message: MessageType): void => {
  if (isInIframe() && window.vtsUrl) {
    window.parent.postMessage(message, window.vtsUrl);
  }
};

export const tellModalOpenStatusToHost = (
  isOpen: boolean,
  modalType: ModalType = 'DEFAULT',
): void => {
  messageHost({
    type: 'MODAL_OPEN_STATUS',
    value: isOpen,
    modalType,
  });
};

export const createHostNotification = (details: Omit<NotificationMessage, 'type'>): void => {
  messageHost({ type: 'NOTIFICATION', ...details });
};

export const sendHostToTourbookPage = ({ id, name }: { id: string; name: string }): void => {
  messageHost({
    type: 'GO_TO_TOURBOOK_PAGE',
    id,
    name,
  });
};

export const sendHostToTourbookAnalyticsPage = ({
  id,
  name,
}: {
  id: string;
  name: string;
}): void => {
  messageHost({
    type: 'GO_TO_TOURBOOK_ANALYTICS_PAGE',
    id,
    name,
  });
};

export const sendHostToMyTourbooksPage = (): void => {
  messageHost({
    type: 'GO_TO_MY_TOURBOOKS_PAGE',
  });
};

export const sendHostToMarketListingsPage = (): void => {
  messageHost({
    type: 'GO_TO_MARKET_LISTINGS_PAGE',
  });
};

export const useIframeMessageListener = (): void => {
  const history = useHistory();

  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      if (event.data.type === 'GO_TO_URL') {
        if (event.data.method === 'push') {
          history.push(event.data.url);
        } else {
          history.replace(event.data.url);
        }
      }
    };

    window.addEventListener('message', messageHandler, false);
    return () => {
      window.removeEventListener('message', messageHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
