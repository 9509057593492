import { TourbookAnalyticsFilterOption } from '@components/layouts/Truva/Tourbook/Analytics/ListingEngagementOverTimeChart';
import { stringify } from 'qs';

const homePageRoutes = {
  exclusiveListings(marketShapeSlug) {
    return `/api/homepage/${marketShapeSlug}/exclusive_listings`;
  },
};

export default {
  browseLocality: ({
    localitySlug,
    abbreviatedRegion,
  }: {
    localitySlug: string;
    abbreviatedRegion: string;
  }) => `/api/browse/regions/${abbreviatedRegion}/localities/${localitySlug}`,
  browseListings: (buildingSlug: string) => `/api/browse/buildings/${buildingSlug}`,
  browseLocalities: ({ region }: { region: string }) => `/api/browse/regions/${region}/localities`,
  browseRegionList: '/api/browse/regions',
  building: (buildingSlug: string) => `/api/buildings/${buildingSlug}`,
  buildingListings: (buildingSlug: string) => `/api/buildings/${buildingSlug}/listings`,
  buildingMapMarkers: buildingSlug => `/api/buildings/${buildingSlug}/map_markers`,
  buildingSave: buildingSlug => `/api/buildings/${buildingSlug}/saves`,
  buildings: '/api/buildings',
  buildingImagesZipUrl: (buildingSlug: string) => `/api/buildings/${buildingSlug}/images_zip_url`,
  currentUser: '/api/current_user',
  currentUserInquirySettings: '/api/current_user/inquiry_settings',
  favoritedListings: '/api/favorited_listings',
  favorites: listingIds => `/api/favorites?${stringify({ listings: listingIds.join(',') })}`,
  globalSearches: query => `/api/global_searches?query=${query}`,
  inquiries: {
    siteVisitor: (listingId: string) => `/api/listings/${listingId}/site_visitor_inquiries`,
    standard: (listingId: string) => `/api/listings/${listingId}/inquiries`,
    quick: (listingId: string) => `/api/listings/${listingId}/quick_inquiries`,
  },
  landlord: landlordSlug => `/api/landlords/${landlordSlug}`,
  landlordImagesZipUrl: (landlordSlug: string) => `/api/landlords/${landlordSlug}/images_zip_url`,
  landlordPreview: (landlordId: string) => `/api/landlord_previews/${landlordId}`,
  landlords: ({ marketSlug, query = '' }: { marketSlug: string; query: string }) =>
    `/api/markets/${marketSlug}/landlords?query=${query}`,
  buildingOwnerSave: landlordSlug => `/api/building_owners/${landlordSlug}/save`,
  landlordPortfolio: ({ landlordSlug, regionSlug }) =>
    `/api/landlords/${landlordSlug}/portfolios/${regionSlug}`,
  listing: listingId => `/api/listings/${listingId}`,
  listingFavorited: listingId => `/api/listings/${listingId}/favorites`,
  listingFavorites: listingId => `/api/listings/${listingId}/favorites`,
  listingShare: (encodedData: string) => `/api/listing_shares/${encodedData}`,
  listingShares: (listingId: string) => `/api/listings/${listingId}/shares`,
  listingImagesZipUrl: (listingId: string) => `/api/listings/${listingId}/images_zip_url`,
  listingSearchFilters: ({
    submarketSlugs,
    landlordsIds,
    buildingIds,
  }: {
    submarketSlugs;
    landlordsIds;
    buildingIds;
  }) =>
    // eslint-disable-next-line max-len
    `/api/listing_search_filters?submarketSlugs=${submarketSlugs}&landlords=${landlordsIds}&buildingIds=${buildingIds}`,
  listings: '/api/listings',
  marketSubmarkets: ({
    marketSlug,
    query,
    limit,
  }: {
    marketSlug: string;
    query: string;
    limit: number;
  }) => `/api/markets/${marketSlug}/submarkets?query=${query}&limit=${limit}`,
  marketBuildings: (marketSlug: string, query: string) =>
    `/api/markets/${marketSlug}/buildings?query=${query}`,
  markets: '/api/markets',
  savedSearchDescription: '/api/saved_search_description',
  savedSearchNotifications: '/api/current_user/saved_search_notifications',
  savedSearches: '/api/saved_searches',
  updateSavedSearchCriteria: (id: string) => `/api/saved_searches/${id}/criteria`,
  savedBuildingOwners: '/api/saved_building_owners',
  savedSearch: (id: string) => `/api/saved_searches/${id}`,
  saveBuilding: () => `/api/saved_buildings`,
  submarkets: ({ longitude, latitude }: { longitude: number; latitude: number }) =>
    `/api/submarkets?longitude=${longitude}&latitude=${latitude}`,
  tourbooks: sort => `/api/tourbooks?sort=${sort}`,
  tourbook: (id: string) => `/api/tourbooks/${id}`,
  tourbookAnalytics: (id: string) => `/api/tourbooks/${id}/analytics`,
  tourbookAnalyticsOverTime: ({
    tourbookId,
    filterBy,
    startDate,
    endDate,
  }: {
    tourbookId: string;
    filterBy: TourbookAnalyticsFilterOption[];
    startDate: string;
    endDate: string;
  }) =>
    // eslint-disable-next-line max-len
    `/api/tourbooks/${tourbookId}/over_time_analytics?filter_by=${filterBy}&start_date=${startDate}&end_date=${endDate}`,
  tourbookListings: (tourbookId: string) => `/api/tourbooks/${tourbookId}/listings`,
  tourbookListing: ({ tourbookId, listingId }: { tourbookId: string; listingId: string }) =>
    `/api/tourbooks/${tourbookId}/listings/${listingId}`,
  tourbookPowerpoint: (id: string) => `/api/tourbooks/${id}/powerpoint`,
  tourbookDuplicate: (id: string) => `/api/tourbooks/${id}/duplicate`,
  tourbookFirstShared: (id: string) => `/api/tourbooks/${id}/first_shared`,
  tourbookTourbookListing: ({
    tourbookId,
    tourbookListingId,
  }: {
    tourbookId: string;
    tourbookListingId: string;
  }) => `/api/tourbooks/${tourbookId}/tourbook_listings/${tourbookListingId}`,
  tourbookExternalListing: ({
    tourbookId,
    externalListingId,
  }: {
    tourbookId: string;
    externalListingId: string;
  }) => `/api/tourbooks/${tourbookId}/external_listings/${externalListingId}`,
  tourbookExternalListings: (tourbookId: string) =>
    `/api/tourbooks/${tourbookId}/external_listings`,
  homepage: homePageRoutes,
  internalLinks: '/api/homepage/internal_links',
} as const;
