import { createContext, useContext } from 'react';
import { Locale } from '@root/types';

export type EnvVariables = {
  railsEnv: 'development' | 'test' | 'production';
  assetHost: string;
  cloudinaryCdn: string;
  cloudinaryCloud: string;
  cloudinaryUploadPreset: string;
  cloudinaryVtsImageCdn: string;
  cloudinaryVtsImageCloud: string;
  cloudinaryVtsImageUploadPreset: string;
  googleMapsKey: string;
  googleReCAPTCHAKey: string;
  launchDarklyClientSideId?: string;
  marketoAccountId: string;
  vtsUrl: string;
  locale: Locale;
};

const EnvContext = createContext<Partial<EnvVariables>>({});

const useEnvVariable = () => useContext(EnvContext);

export const EnvProvider = EnvContext.Provider;
export default useEnvVariable;
