export default {
  // convertable units
  sf: 'sf',
  sm: 'sm',
  // marketplace Area units
  SquareFoot: 'sf',
  squarefoot: 'sf',
  SquareMeter: 'sqm',
  'Square Feet': 'sf', // This is because Listing Prices have the wrong units... This is temporary and we will fix this! DO NOT USE SQUARE FEET AS A UNIT!
};
