import { combineReducers } from 'redux';
import { Breadcrumbs, Action } from '@root/types';

export const defaultState = {
  breadcrumbs: [] as Breadcrumbs,
  islistingPreviewModalOpen: false,
  visualizeAnalytics: false,
};

function breadcrumbReducer(state: Breadcrumbs = defaultState.breadcrumbs, action: Action) {
  switch (action.type) {
    case 'ADD_BREADCRUMBS': {
      return action.payload.breadcrumbs;
    }

    case 'REMOVE_BREADCRUMBS': {
      return defaultState.breadcrumbs;
    }

    default: {
      return state;
    }
  }
}

function isListingPreviewModalOpenReducer(
  state: boolean = defaultState.islistingPreviewModalOpen,
  action: Action,
) {
  switch (action.type) {
    case 'OPEN_LISTING_PREVIEW': {
      return true;
    }

    case 'CLOSE_LISTING_PREVIEW': {
      return false;
    }

    default: {
      return state;
    }
  }
}

function visualizeAnalyticsReducer(
  state: boolean = defaultState.visualizeAnalytics,
  action: Action,
) {
  switch (action.type) {
    case 'VISUALIZING_ANALYTICS_TOGGLED':
      return !state;
    default: {
      return state;
    }
  }
}

const UIReducer = combineReducers({
  breadcrumbs: breadcrumbReducer,
  islistingPreviewModalOpen: isListingPreviewModalOpenReducer,
  visualizeAnalytics: visualizeAnalyticsReducer,
});

export type UIStoreState = ReturnType<typeof UIReducer>;
export default UIReducer;
