import { ConvertableMoneyPerAreaPerPeriod, LocaleUpCase } from '@root/types';
import formatCurrency from './formatCurrency';
import units from './units';
import periods from './periods';

type args = {
  min: ConvertableMoneyPerAreaPerPeriod | null;
  max: ConvertableMoneyPerAreaPerPeriod | null;
  locale: LocaleUpCase;
  options: Intl.NumberFormatOptions | undefined | null;
};

export default ({ min, max, locale, options = {} }: args) => {
  if (!min && !max) return null;

  const minCurrencyWithUnits =
    min &&
    formatCurrency({
      magnitude: Math.round(min.magnitude!),
      currency: min.units.money,
      locale,
      options,
    });
  const maxCurrencyWithUnits =
    max &&
    formatCurrency({
      magnitude: Math.round(max.magnitude!),
      currency: max.units.money,
      locale,
      options,
    });

  const formattedAreaUnit = units[min?.units.area! || max?.units.area!];
  const formattedTimePeriod = periods[min?.units.period! || max?.units.period!];

  if (minCurrencyWithUnits && maxCurrencyWithUnits) {
    // eslint-disable-next-line max-len
    return `${minCurrencyWithUnits} - ${maxCurrencyWithUnits} ${formattedAreaUnit} / ${formattedTimePeriod}`;
  }

  return `${
    minCurrencyWithUnits || maxCurrencyWithUnits
  } ${formattedAreaUnit} / ${formattedTimePeriod}`;
};
