const abbreviatedPeriods = {
  yr: 'yr',
  mo: 'mo',
  year: 'yr',
  month: 'mo',
  Month: 'mo',
  Year: 'yr',
};

const periods = {
  year: 'year',
  month: 'month',
};

const getPeriod = (key, abbreviated) => {
  if (abbreviated) {
    return abbreviatedPeriods[key];
  }
  return periods[key];
};

export { getPeriod };
export default abbreviatedPeriods;
