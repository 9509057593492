import { compact } from 'lodash';
import { combineReducers } from 'redux';
import { TourbookSummary, Action, Tourbook, ExternalListing } from '@root/types';
import normalize from '@shared/normalize';

export const defaultState: TourbookStoreState = {
  byId: {},
  summaryById: {},
  visibleSummaryIds: [],
  summariesLoaded: false,
  externalListingsById: {},
};

type SummaryByIdState = Record<string, TourbookSummary>;
function summaryByIdReducer(state: SummaryByIdState = defaultState.summaryById, action: Action) {
  switch (action.type) {
    case 'RECEIVE_FETCHED_TOURBOOKS': {
      return {
        ...state,
        ...normalize(action.payload.tourbooks),
      };
    }
    case 'LISTING_ADDED_TO_TOURBOOK':
    case 'LISTING_REMOVED_FROM_TOURBOOK': {
      return {
        ...state,
        [action.payload.tourbook.id]: action.payload.tourbook,
      };
    }
    case 'RECEIVE_NEW_TOURBOOK': {
      return {
        ...state,
        [action.payload.tourbook.id]: action.payload.tourbook,
      };
    }
    case 'REMOVE_TOURBOOK': {
      const newState = { ...state };
      delete newState[action.payload.tourbook];
      return newState;
    }
    default:
      return state;
  }
}

type VisibleSummaryIdsState = string[];
function visibleSummaryIdsReducer(
  state: VisibleSummaryIdsState = defaultState.visibleSummaryIds,
  action: Action,
) {
  switch (action.type) {
    case 'RECEIVE_FETCHED_TOURBOOKS': {
      return action.payload.tourbooks.map(tourbook => tourbook.id);
    }
    case 'RECEIVE_NEW_TOURBOOK': {
      return [action.payload.tourbook.id, ...state];
    }
    default:
      return state;
  }
}

type ByIdState = Record<string, Tourbook>;
function byIdReducer(state: ByIdState = defaultState.byId, action: Action): ByIdState {
  switch (action.type) {
    case 'RECEIVE_FETCHED_TOURBOOK':
    case 'RECEIVE_UPDATED_TOURBOOK': {
      return {
        ...state,
        [action.payload.tourbook.id]: action.payload.tourbook,
      };
    }

    case 'RECEIVE_UPDATED_TOURBOOK_LISTING': {
      const { tourbookId, tourbookListing } = action.payload;

      const tourbook = state[tourbookId];
      const tourbookListings = tourbook.listings;
      const tourbookListingIndex = tourbookListings.findIndex(
        listing => listing.id === tourbookListing.id,
      );

      if (tourbookListingIndex >= 0) {
        tourbookListings[tourbookListingIndex] = tourbookListing;
      }
      return {
        ...state,
        [tourbookId]: {
          ...tourbook,
          listings: tourbookListings,
        },
      };
    }

    case 'REORDER_TOURBOOK_LISTINGS': {
      const { tourbookId, sortableIds } = action.payload;
      const tourbook = state[tourbookId];
      const listings = compact(
        sortableIds.map(sortableId =>
          tourbook?.listings?.find(listing => listing.sortableId === sortableId),
        ),
      );

      return {
        ...state,
        [tourbookId]: {
          ...tourbook,
          listings,
        },
      };
    }

    default:
      return state;
  }
}

type SummariesLoadedState = boolean;
function summariesLoadedReducer(state: SummariesLoadedState = false, action: Action) {
  switch (action.type) {
    case 'TOURBOOKS_LOADED': {
      return true;
    }
    default:
      return state;
  }
}

type ExternalListingsByIdState = Record<string, ExternalListing>;
function externalListingsByIdReducer(
  state: ExternalListingsByIdState = defaultState.externalListingsById,
  action: Action,
) {
  switch (action.type) {
    case 'RECEIVE_FETCHED_EXTERNAL_LISTING': {
      return {
        ...state,
        ...normalize(action.payload.externalListing),
      };
    }
    case 'RECEIVE_UPDATED_TOURBOOK_LISTING': {
      const { tourbookListing } = action.payload;
      if (!tourbookListing.external) return state;

      const newState = { ...state };
      delete newState[tourbookListing.id];

      return newState;
    }
    default:
      return state;
  }
}

const tourbookReducer = combineReducers({
  byId: byIdReducer,
  summaryById: summaryByIdReducer,
  visibleSummaryIds: visibleSummaryIdsReducer,
  summariesLoaded: summariesLoadedReducer,
  externalListingsById: externalListingsByIdReducer,
});

export type TourbookStoreState = {
  byId: ByIdState;
  summaryById: SummaryByIdState;
  visibleSummaryIds: VisibleSummaryIdsState;
  summariesLoaded: SummariesLoadedState;
  externalListingsById: ExternalListingsByIdState;
};

export default tourbookReducer;
