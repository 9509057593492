import {} from 'lodash';
import {
  StoreState,
  SavedSearch,
  TourbookSummary,
  Tourbook,
  ExternalListing,
  LastInquiry,
} from '@root/types';

export const hasCurrentUserLoaded = (state: StoreState) => !!state.loadingStates.currentUser;
export const hasAdminUser = (state: StoreState): boolean =>
  !!state.currentUser && state.currentUser.role === 'admin';

export const isListingSaved = (state: StoreState, listingId: string) =>
  !!state.savedListings.statusById[listingId];

export const getListing = (state: StoreState, listingId: string) => state.listings.byId[listingId];
export const getSearchListing = (state: StoreState, listingId: string) =>
  state.listings.searchResults[listingId];

export const getLoadingState = (state: StoreState, entity: string): boolean =>
  !!state.loadingStates[entity];

export const getBuildingBySlug = (state: StoreState, slug: string) => {
  const buildings = Object.values(state.buildings.byId);
  return buildings.find(building => building.slug === slug);
};

export const getListingCount = (state: StoreState) => state.listings.pagination?.totalCount || 0;

export const getSearchListingIds = (state: StoreState) => state.listings.pagination?.ids || [];

export const getCurrentPageType = (state: StoreState) => state.page.type;

export const hasBreadcrumbs = (state: StoreState): boolean => state.ui.breadcrumbs.length !== 0;

export const getListingSearchPageLevelArguments = (state: StoreState) => ({
  pageNumber: state.page.pageArguments?.pageNumber,
  resultsCount: state.listings.pagination?.totalCount,
});

export const getSavedSearches = (state: StoreState): SavedSearch[] =>
  state.savedSearches.visibleIds.map(id => state.savedSearches.byId[id]!);

export const getSavedSearchesLoading = (state: StoreState): boolean =>
  state.savedSearches.loading && state.savedSearches.visibleIds.length === 0;

export const getSavedBuildingOwners = (state: StoreState) => state.savedBuildingOwners.onSavedPage;
export const getSavedListings = (state: StoreState) => state.savedListings.onSavedPage;

export const getVisibleTourbookSummaries = (state: StoreState): TourbookSummary[] => {
  const { visibleSummaryIds, summaryById } = state.tourbooks;
  return visibleSummaryIds
    .map(id => summaryById[id])
    .filter(tourbook => !!tourbook) as TourbookSummary[];
};
export const getTourbookById = (state: StoreState, id: string): Tourbook | undefined =>
  state.tourbooks.byId[id];

export const tourbooksLoaded = (state: StoreState): boolean => !!state.loadingStates.tourbooks;

export const tourbookSummariesLoaded = (state: StoreState): boolean =>
  state.tourbooks.summariesLoaded;
export const tourbookCreateOnly = (state: StoreState): boolean =>
  state.tourbooks.visibleSummaryIds.length === 0;

export const getExternalListing = (state: StoreState, id: string): ExternalListing | undefined =>
  state.tourbooks.externalListingsById[id];

export const getQuickInquiryDefaultMessage = (state: StoreState): string | null | undefined => {
  return state.currentUser?.quickInquiryDefaultMessage;
};

export const getLastInquiryForListing = (
  state: StoreState,
  listingId: string,
): LastInquiry | undefined => state.listings.lastInquiryById[listingId];
