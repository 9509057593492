import { Address } from '@root/types';

export default function formatFullAddress({
  street,
  city,
  abbreviatedRegion,
  postalCode,
  countryCode,
}: Address): string {
  return `${street}, ${city}, ${abbreviatedRegion} ${postalCode} ${countryCode}`;
}
