import { LocaleUpCase } from '@root/types';

type args = {
  magnitude: number | string;
  currency: string;
  locale: LocaleUpCase;
  options: Intl.NumberFormatOptions | undefined | null;
};

export default ({ magnitude, currency, locale, options = {} }: args) => {
  const amount = Number(magnitude);
  const shouldNotShowCents = amount % 1 === 0;
  const precision = shouldNotShowCents ? 0 : 2;

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    currencyDisplay: 'symbol',
    ...options,
  });

  /* eslint-disable no-fallthrough */
  return formatter
    .formatToParts(amount)
    .map(({ type, value }) => {
      switch (type) {
        case 'currency':
          if (value === 'US$') return `US $`;
        default:
          return value;
      }
    })
    .reduce((string, part) => `${string}${part}`);
  /* eslint-enable no-fallthrough */
};
