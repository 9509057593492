import { combineReducers } from 'redux';
import { Action, BuildingOwnerSummary } from '@root/types';

export const defaultState = {
  onSavedPage: null,
};

function onSavedPageReducer(
  state: BuildingOwnerSummary[] | null = defaultState.onSavedPage,
  action: Action,
) {
  switch (action.type) {
    case 'RECEIVE_FETCHED_BUILDING_OWNERS': {
      return action.payload.buildingOwners;
    }
    case 'RECEIVE_UPDATED_SAVED_BUILDING_OWNER': {
      return state!.map(owner => {
        if (owner.id === action.payload.id) {
          return action.payload;
        }
        return owner;
      });
    }
    default:
      return state;
  }
}

const savedBuildingOwnersReducer = combineReducers({
  onSavedPage: onSavedPageReducer,
});
export default savedBuildingOwnersReducer;
export type SavedBuildingOwnersStoreState = {
  onSavedPage: BuildingOwnerSummary[] | null;
};
