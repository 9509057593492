import normalize from '@shared/normalize';
import { SavedSearch, Action } from '@root/types';

export type SavedSearchesStoreState = {
  byId: Record<string, SavedSearch | undefined>;
  visibleIds: string[];
  loading: boolean;
  activeId: string | null;
};
export const defaultState = { byId: {}, visibleIds: [], loading: false, activeId: null };

export default function savedSearchesReducer(
  state: SavedSearchesStoreState = defaultState,
  action: Action,
) {
  switch (action.type) {
    case 'RECEIVE_UPDATED_SAVED_SEARCH': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload,
        },
      };
    }
    case 'RECEIVED_UPDATED_ALL_SAVED_SEARCH_NOTIFICATIONS_ACTION': {
      return {
        ...state,
        byId: {
          ...state.byId,
          ...action.payload.reduce((result, search) => ({ ...result, [search.id]: search }), {}),
        },
      };
    }
    case 'REQUEST_SAVED_SEARCHES': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'RECEIVE_SAVED_SEARCHES': {
      return {
        ...state,
        byId: { ...state.byId, ...normalize(action.payload) },
        visibleIds: action.payload.map(search => search.id),
        loading: false,
      };
    }
    case 'SAVED_SEARCH_SELECTED': {
      return {
        ...state,
        activeId: action.payload,
      };
    }
    default:
      return state;
  }
}
