import { Breadcrumbs } from '@root/types';
import manageTourbookPageActions, {
  Actions as ManageTourbookPageActions,
} from './manageTourbookPageActions';

type AddBreadcrumbsAction = { type: 'ADD_BREADCRUMBS'; payload: { breadcrumbs: Breadcrumbs } };
const addBreadcrumbs = (breadcrumbs: Breadcrumbs): AddBreadcrumbsAction => ({
  type: 'ADD_BREADCRUMBS',
  payload: { breadcrumbs },
});

type RemoveBreadcrumbsAction = { type: 'REMOVE_BREADCRUMBS' };
const removeBreadcrumbs = (): RemoveBreadcrumbsAction => ({
  type: 'REMOVE_BREADCRUMBS',
});

type OpenListingPreview = { type: 'OPEN_LISTING_PREVIEW' };
type CloseListingPreview = { type: 'CLOSE_LISTING_PREVIEW' };

export default {
  addBreadcrumbs,
  removeBreadcrumbs,
  ...manageTourbookPageActions,
};

export type Actions =
  | AddBreadcrumbsAction
  | RemoveBreadcrumbsAction
  | ManageTourbookPageActions
  | OpenListingPreview
  | CloseListingPreview;
