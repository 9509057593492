import { format, parse } from 'date-fns';
import { Locale } from '@root/types';

const ukDateFormat = 'dd/MM/yy';
const defaultDateFormat = 'M/d/yy';

export const dateFormat = (locale: Locale): string => {
  if (locale === 'en-gb') {
    return ukDateFormat;
  }
  return defaultDateFormat;
};

type args = { date: string; locale: Locale };

export default ({ date, locale }: args): string | null => {
  if (!date) {
    return null;
  }

  if (locale === 'en-gb') {
    return format(parse(date, 'yyyy-MM-dd', new Date()), ukDateFormat);
  }
  return format(parse(date, 'yyyy-MM-dd', new Date()), defaultDateFormat);
};
