import { getMarketFriendlySourcePage } from '@root/store/analyticsMiddleware';
import { PARAMETERS, EVENT_TYPES, EVENTS } from '@root/tracking/constants';

const manageTourbookPageLoadedAction = {
  type: 'MANAGE_TOURBOOKS_PAGE_LOADED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.page,
        event: PARAMETERS.manageTourbooksPage,
      },
    },
  },
} as const;
type ManageTourbookPageLoadedAction = typeof manageTourbookPageLoadedAction;
export const manageTourbookPageLoaded = () => manageTourbookPageLoadedAction;

type ClickedToTourbook = {
  type: 'CLICKED_TO_TOURBOOK';
  payload: {
    meta: {
      analytics: {
        eventType: typeof EVENT_TYPES.track;
        event: typeof EVENTS.clickToPage;
        sourcePage: typeof PARAMETERS.manageTourbooksPage;
        destination: typeof PARAMETERS.tourbookPage | typeof PARAMETERS.marketTourbookPage;
      };
    };
  };
};
export const clickedToTourbook = () => ({
  type: 'CLICKED_TO_TOURBOOK',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        sourcePage: PARAMETERS.manageTourbooksPage,
        destination: getMarketFriendlySourcePage(PARAMETERS.tourbookPage),
      },
    },
  },
});

export default {
  clickedToTourbook,
  manageTourbookPageLoaded,
};

export type Actions = ClickedToTourbook | ManageTourbookPageLoadedAction;
