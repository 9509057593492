import { UnitAndMagnitudeMeasure } from '@root/types';
import numeral from 'numeral';

export default function formatLength(length: UnitAndMagnitudeMeasure | null | undefined): string {
  if (!length) return '';

  const { magnitude, unit } = length;
  const numberMagnitude: number =
    typeof magnitude === 'number' ? magnitude : parseInt(magnitude, 10);

  // eslint-disable-next-line default-case
  switch (unit) {
    case 'Inches': {
      const feet = Math.floor(numberMagnitude / 12);
      const inches = Math.abs(numberMagnitude % 12);

      return inches > 0 ? `${feet}' ${inches}"` : `${feet}'`;
    }
    case 'Centimeters': {
      const meters = numeral(numberMagnitude / 100).format('0,0[.][00]');

      return `${meters} m`;
    }
  }

  // shouldnt be hit, eslint wants it
  return '';
}
