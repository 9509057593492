import api from '@shared/api';
import { ExternalListing } from '@root/types';
import routes from '@root/routes';
import actions from '../index';

const handleErrors = async response => {
  if (response.ok) return response;

  const errors = await response.json();
  throw Error(errors);
};

type ReceiveExternalListingFetchAction = {
  type: 'RECEIVE_FETCHED_EXTERNAL_LISTING';
  payload: {
    externalListing: ExternalListing;
  };
};

export const receiveExternalListing = (
  externalListing: ExternalListing,
): ReceiveExternalListingFetchAction => ({
  type: 'RECEIVE_FETCHED_EXTERNAL_LISTING',
  payload: {
    externalListing,
  },
});
const editExternalListingPageLoad =
  ({ tourbookId, externalListingId }: { tourbookId: string; externalListingId: string }) =>
  async dispatch => {
    await dispatch(actions.setTourbookEditExternalListingPage({ tourbookId, externalListingId }));
    await api
      .fetch(routes.api.tourbookExternalListing({ tourbookId, externalListingId }))
      .then(handleErrors)
      .then(response => response.json())
      .then((externalListing: ExternalListing) => {
        dispatch(receiveExternalListing(externalListing));
      });
  };

export default editExternalListingPageLoad;
export type Actions = ReceiveExternalListingFetchAction;
