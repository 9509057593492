import { initializePendoForKnownUser, initializePendoForAnonymousUser } from '@shared/pendoClient';
import { RECEIVE_CURRENT_USER, RECEIVE_NO_USER } from './entities';

type PendoInitializedAction = {
  type: 'PENDO_INITIALIZED';
  payload: {
    meta: {
      userId: string;
    };
  };
};
const pendoInitialized = (userId: string): PendoInitializedAction => ({
  type: 'PENDO_INITIALIZED',
  payload: {
    meta: {
      userId,
    },
  },
});

export default function initializePendoMiddleware({ dispatch }) {
  return next => action => {
    next(action);

    if (action.type === RECEIVE_CURRENT_USER) {
      const currentUser = action.payload.user;
      initializePendoForKnownUser(currentUser);
      dispatch(pendoInitialized(currentUser.id));
    } else if (action.type === RECEIVE_NO_USER) {
      initializePendoForAnonymousUser();
      dispatch(pendoInitialized('anonymous'));
    }
  };
}
