import { combineReducers } from 'redux';
import { SearchListing, Action } from '@root/types';

export const defaultState = {
  onSavedPage: [],
  statusById: {},
};

type StatusByIdState = { [listingId: string]: boolean };
function statusByIdReducer(state: StatusByIdState = defaultState.statusById, action: Action) {
  switch (action.type) {
    case 'RECEIVE_SEARCH_LISTINGS_FROM_LISTING_PREVIEW':
    case 'RECEIVE_SAVED_STATUSES':
    case 'RECEIVE_SEARCH_LISTINGS': {
      const newStatuses = action.payload.savedStatuses.reduce(
        (accum: StatusByIdState, status: { listingId: string; saved: boolean }) => ({
          ...accum,
          [status.listingId]: status.saved,
        }),
        {},
      );
      return {
        ...state,
        ...newStatuses,
      };
    }

    case 'RECEIVE_FETCHED_SAVED_LISTINGS': {
      const receivedState = action.payload.listings.reduce(
        (accum: StatusByIdState, listing: SearchListing) => ({
          ...accum,
          [listing.id]: true,
        }),
        {},
      );

      return {
        ...state,
        ...receivedState,
      };
    }

    case 'LISTING_UNSAVED': {
      return {
        ...state,
        [action.payload.listingId]: false,
      };
    }

    case 'LISTING_SAVED': {
      return {
        ...state,
        [action.payload.listingId]: true,
      };
    }

    default: {
      return state;
    }
  }
}

export function onSavedPageReducer(
  state: SearchListing[] = defaultState.onSavedPage,
  action: Action,
) {
  switch (action.type) {
    case 'RECEIVE_FETCHED_SAVED_LISTINGS': {
      return action.payload.listings;
    }
    default:
      return state;
  }
}

const savedListingsReducer = combineReducers({
  onSavedPage: onSavedPageReducer,
  statusById: statusByIdReducer,
});

export type SavedListingsStoreState = {
  onSavedPage: SearchListing[];
  statusById: StatusByIdState;
};

export default savedListingsReducer;
