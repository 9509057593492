import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import numeral from 'numeral';
import {
  formatArea,
  formatAreaPerPeriod,
  formatMinMaxArea,
  formatCurrency,
  formatCurrencyPerAreaPerPeriod,
  formatCurrencyPerPeriod,
  formatCurrencyPerPeriodRange,
  formatDate,
  formatLength,
  formatCurrencyPerAreaPerPeriodRange,
  formatCityState,
  formatCityLocalityRegion,
  formatFullAddress,
  formatFullBuildingName,
  units,
} from '@shared/TranslationHelpers';
import formatPhoneNumber from '@shared/formatPhoneNumber';

export default function configureTranslations(locale, translations) {
  return i18n.use(initReactI18next).init({
    initImmediate: false,
    debug: false,
    lng: locale,
    lowerCaseLng: true,
    compatibilityJSON: 'v3',
    fallbackLng: 'en-us',
    resources: translations,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
      format(value, format) {
        if (format === 'number') return numeral(value).format();
        if (format === 'numberAbbreviated') return numeral(value).format('0.0a');
        if (format === 'unit') return units[value];
        if (format === 'area') return formatArea(value);
        if (format === 'minMaxAreaFormat') return formatMinMaxArea(value);
        if (format === 'pricePerAreaPerPeriod')
          return formatCurrencyPerAreaPerPeriod({ ...value, locale });
        if (format === 'pricePerAreaPerPeriodRange')
          return formatCurrencyPerAreaPerPeriodRange({ ...value, locale });
        if (format === 'pricePerPeriod') return formatCurrencyPerPeriod({ ...value, locale });
        if (format === 'pricePerPeriodRange')
          return formatCurrencyPerPeriodRange({ ...value, locale });
        if (format === 'currency') return formatCurrency({ ...value, locale });
        if (format === 'areaPerPeriod') return formatAreaPerPeriod({ ...value, locale });
        if (format === 'date') return formatDate({ date: value, locale });
        if (format === 'length') return formatLength(value);
        if (format === 'formattedPhone') return formatPhoneNumber(value);
        if (format === 'cityState') return formatCityState(value);
        if (format === 'cityLocalityRegion') return formatCityLocalityRegion(value);
        if (format === 'fullAddress') return formatFullAddress(value);
        if (format === 'fullBuildingName') return formatFullBuildingName(value);
        return value;
      },
    },
  });
}
