import { Address } from '@root/types';

export default function formatCityLocalityRegion({
  city,
  postalCode,
  countryCode,
  abbreviatedRegion,
}: Address): string {
  switch (countryCode?.toLowerCase()) {
    case 'gb':
      return `${city} ${postalCode}`;
    case 'ae':
      return city;
    default:
      return `${city}, ${abbreviatedRegion} ${postalCode}`;
  }
}
