import cn from 'classnames';
import type { SourcePage, SourceContent } from '@root/store/actions/videoAnalytics';
import { MutableRefObject } from 'react';
import useVideoPlayer from '@shared/useCloudinaryVideoPlayer';
import type { TourbookListingAnalyticsInformation, Video } from '@root/types';
import s from './VideoPlayer.module.less';

type Props = {
  video: Video;
  autoplay?: boolean;
  className?: string;
  testId?: string;
  sourceContent: SourceContent | string;
  sourcePage: SourcePage;
  fill?: boolean;
  fluid?: boolean;
  playerRef?: MutableRefObject<any>;
  analyticsInformation?: TourbookListingAnalyticsInformation | undefined;
  fitWithinViewport?: boolean;
};

const VideoPlayer = ({
  video,
  autoplay = false,
  className,
  testId = 'video',
  sourceContent,
  sourcePage,
  analyticsInformation,
  fill = false,
  fluid = true,
  playerRef,
  fitWithinViewport = false,
}: Props) => {
  const { videoTagRef, player } = useVideoPlayer({
    autoplay,
    sourcePage,
    sourceContent,
    analyticsInformation,
    video,
    fill,
    fluid,
  });

  if (playerRef) {
    // eslint-disable-next-line no-param-reassign
    playerRef.current = player;
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={cn(s.container, className)}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {/* eslint-disable react/jsx-no-literals */}
      <video
        data-testid={testId}
        ref={videoTagRef}
        autoPlay={autoplay}
        controls
        poster={video.posterPath}
        className={cn('cld-video-player', fitWithinViewport && '!h-[100vh] !p-0')}
        data-cld-colors={'{"accent": "#5528FF", "text": "#fff" }'}
      ></video>
      {/* eslint-enable react/jsx-no-literals */}
    </div>
  );
};

export default VideoPlayer;
