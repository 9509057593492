import { reduce, isArray, isEmpty, isNil } from 'lodash';

export default function queryStringSerializer(params) {
  return reduce(
    params,
    (agg, value, key) => {
      if (isNil(value)) return agg;

      let paramString;

      if (isArray(value)) {
        if (isEmpty(value)) {
          return agg;
        }
        paramString = reduce(
          value,
          (result, n) => {
            const delimiter = result === '' ? '' : '&';

            return `${result}${delimiter}${key}[]=${encodeURIComponent(n)}`;
          },
          '',
        );
      } else {
        paramString = `${key}=${encodeURIComponent(value)}`;
      }
      const delimiter = agg === '' ? '' : '&';

      return `${agg}${delimiter}${paramString}`;
    },
    '',
  );
}
