import * as React from 'react';
import { notification } from 'antd';
import { createHostNotification, isInIframe } from '@root/shared/iframeUtils';
import { NotificationLink, NotificationStyle } from '@root/types/Iframe';
import { isString } from 'lodash';
import { ErrorIcon } from '@viewthespace/components';
import { ArgsProps } from 'antd/lib/notification';
import CustomIcon from '../CustomIcon';
import s from './Notification.module.less';

type Placement = 'bottomLeft' | 'topLeft' | 'topRight' | 'bottomRight';
type Args = {
  title?: React.ReactNode;
  text?: React.ReactNode;
  placement?: Placement;
  top?: number;
  className?: string | null;
  duration?: number;
  iframe?: {
    text?: string;
    links?: NotificationLink[];
    displayNotificationInIframe?: boolean;
  };
  key?: string;
};

type IframeArgs = {
  style: NotificationStyle;
  text?: string;
  links?: NotificationLink[];
  displayNotificationInIframe?: boolean;
};

const DefaultDuration = 3.5;
const DefaultPlacement = 'bottomLeft';
const closeIcon = <CustomIcon type="close" />;

const createNotification = (args: ArgsProps, iframeArgs: IframeArgs): void => {
  if (!isInIframe() || iframeArgs.displayNotificationInIframe) {
    notification.open(args);
  } else {
    const getText = () => {
      if (iframeArgs.text) return iframeArgs.text;
      return isString(args.description) ? args.description : null;
    };

    createHostNotification({
      ...iframeArgs,
      title: isString(args.message) ? args.message : null,
      text: getText(),
      links: iframeArgs.links || [],
    });
  }
};

export default {
  success: ({
    title,
    text,
    placement = DefaultPlacement,
    duration = DefaultDuration,
    iframe,
  }: Args) => {
    createNotification(
      {
        message: title,
        description: text,
        className: s.successNotification,
        placement,
        closeIcon,
        duration,
      },
      {
        style: 'success',
        ...iframe,
      },
    );
  },
  info: ({
    title,
    text,
    placement = DefaultPlacement,
    duration = DefaultDuration,
    iframe,
  }: Args) => {
    createNotification(
      {
        message: title,
        description: text,
        className: s.infoNotification,
        placement,
        duration,
        closeIcon,
      },
      {
        style: 'success',
        ...iframe,
      },
    );
  },
  error: ({ title, text, placement = DefaultPlacement, iframe, key }: Args) => {
    createNotification(
      {
        message: title,
        description: text,
        className: s.errorNotification,
        placement,
        duration: null,
        icon: <ErrorIcon className="text-text-critical" />,
        closeIcon,
        key,
      },
      {
        style: 'alert',
        ...iframe,
      },
    );
  },
  saveSuccess: ({
    title,
    text,
    placement = DefaultPlacement,
    top,
    duration = DefaultDuration,
    iframe,
  }: Args) => {
    createNotification(
      {
        message: title,
        className: s.saveNotification,
        placement,
        top,
        duration,
        description: text,
        icon: <CustomIcon type="heart" className={s.heartIcon} />,
        closeIcon,
      },
      {
        style: 'success',
        ...iframe,
      },
    );
  },
};
