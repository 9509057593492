import { CurrencyWithSentenceCase, LocaleUpCase } from '@root/types';
import formatCurrency from './formatCurrency';
import units from './units';
import periods from './periods';

type args = {
  area: 'SquareFoot' | 'SquareMeter' | 'sf' | 'sm';
  currency: CurrencyWithSentenceCase;
  locale: LocaleUpCase;
  magnitude: number | string;
  maxMagnitude?: number | string | null;
  options: Intl.NumberFormatOptions | undefined | null;
  period: 'month' | 'year' | 'mo' | 'yr';
};

export default ({
  area,
  currency,
  locale,
  magnitude,
  maxMagnitude = null,
  options = {},
  period,
}: args) => {
  const currencyWithUnits = magnitude && formatCurrency({ magnitude, currency, locale, options });
  const maxCurrencyWithUnits =
    maxMagnitude && formatCurrency({ magnitude: maxMagnitude, currency, locale, options });
  const formattedAreaUnit = units[area];
  const formattedTimePeriod = periods[period];

  if (currencyWithUnits && maxCurrencyWithUnits) {
    // eslint-disable-next-line max-len
    return `${currencyWithUnits} - ${maxCurrencyWithUnits} ${formattedAreaUnit} / ${formattedTimePeriod}`;
  }

  return `${
    currencyWithUnits || maxCurrencyWithUnits
  } ${formattedAreaUnit} / ${formattedTimePeriod}`;
};
