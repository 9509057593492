import normalize from '@shared/normalize';
import { Shape, Action } from '@root/types';

export type ShapeStoreState = {
  bySlug: Record<string, Shape>;
  startingSlugs: string[];
};

export const defaultState = {
  bySlug: {},
  startingSlugs: [],
};

export default function shapesReducer(state: ShapeStoreState = defaultState, action: Action) {
  switch (action.type) {
    case 'LISTING_SEARCH_PAGE_SERVER_SIDE_RENDER_INITIAL_LOAD': {
      const { shapes } = action.payload;

      return {
        ...state,
        startingSlugs: shapes.map((shape: Shape) => shape.slug),
        bySlug: { ...state.bySlug, ...normalize(shapes, { attr: 'slug' }) },
      };
    }
    case 'RECEIVE_SEARCH_BUILDINGS': {
      return {
        ...state,
        bySlug: { ...state.bySlug, ...normalize(action.payload.shapes, { attr: 'slug' }) },
      };
    }
    default:
      return state;
  }
}
