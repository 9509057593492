import { CurrencyWithSentenceCase, LocaleUpCase } from '@root/types';
import formatCurrency from './formatCurrency';
import { getPeriod } from './periods';

type args = {
  abbreviatedPeriod: boolean | undefined | null;
  currency: CurrencyWithSentenceCase;
  locale: LocaleUpCase;
  magnitude: number | string;
  maxMagnitude?: number | string | null;
  options: Intl.NumberFormatOptions | undefined | null;
  period: 'month' | 'year' | 'mo' | 'yr';
};

export default ({
  abbreviatedPeriod = false,
  currency,
  locale,
  magnitude,
  maxMagnitude = null,
  options = {},
  period,
}: args) => {
  const currencyWithUnits = formatCurrency({ magnitude, currency, locale, options });
  const maxCurrencyWithUnits =
    maxMagnitude && formatCurrency({ magnitude: maxMagnitude, currency, locale, options });
  const formattedTimePeriod = getPeriod(period, abbreviatedPeriod);
  if (currencyWithUnits && maxCurrencyWithUnits) {
    // eslint-disable-next-line max-len
    return `${currencyWithUnits} - ${maxCurrencyWithUnits} / ${formattedTimePeriod}`;
  }
  return `${currencyWithUnits || maxCurrencyWithUnits} / ${formattedTimePeriod}`;
};
