export default function formatPhoneNumber(input?: string | null | undefined): string {
  if (!input) return '';

  const number = input.replace(/\D/g, '');
  const { length } = number;

  if (length === 10) {
    return `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6, 10)}`;
  }
  if (length === 11) {
    return `${number.substring(0, 3)} ${number.substring(3, 7)} ${number.substring(7, 11)}`;
  }
  if (length === 13) {
    return `+${number.substring(0, 2)} ${number.substring(2, 5)} ${number.substring(
      5,
      9,
    )} ${number.substring(9, 13)}`;
  }

  return number;
}
