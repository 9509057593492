import { Action } from '@root/types';

export type Page =
  | 'LISTING'
  | 'LISTING_SHARE'
  | 'LISTING_SEARCH'
  | 'BUILDING'
  | 'LANDLORD'
  | 'VIDEO_EMBED'
  | 'TOURBOOK'
  | 'TOURBOOK_EXTERNAL_LISTING'
  | 'TOURBOOK_EDIT_EXTERNAL_LISTING'
  | 'TOURBOOK_ANALYTICS'
  | 'MANAGE_TOURBOOKS'
  | 'SAVED_BUILDINGS'
  | 'SAVED_SEARCHES'
  | 'SAVED_LISTINGS'
  | 'SAVED_BUILDING_OWNERS'
  | 'ACCOUNT_SETTINGS'
  | 'HOMEPAGE'
  | 'NOT_FOUND';

export type PageStoreState = {
  type?: Page;
  pageArguments?: any; // will do later if it matters ;)
};
const defaultState = {};

export default function pageReducer(state: PageStoreState = defaultState, action: Action) {
  switch (action.type) {
    case 'SET_PAGE': {
      return action.payload;
    }
    default:
      return state;
  }
}
