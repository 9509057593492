export const EVENTS = {
  areasInteraction: 'Areas Interaction',
  attachmentInteraction: 'Attachment Interaction',
  carouselInteraction: 'Carousel Interaction',
  clickToPage: 'Click to Page',
  contactInteraction: 'Contact Interaction',
  customShareInteraction: 'Share Interaction',
  filterInteraction: 'Filter Interaction',
  galleryInteraction: 'Gallery Interaction',
  inquiryInteraction: 'Inquiry Interaction',
  lightboxInteraction: 'Lightbox Interaction',
  listingPreviewInteraction: 'Listing Preview Interaction',
  listingTableInteraction: 'Listing Table Interaction',
  mapInteraction: 'Map Interaction',
  pricingInteraction: 'Pricing Interaction',
  savedSearchInteraction: 'Saved Search Interaction',
  saveInteraction: 'Save Interaction',
  shareInteraction: 'Share Interaction',
  sortInteraction: 'Sort Interaction',
  threeDTourInteraction: '3D Tour Interaction',
  tourbookInteraction: 'Tourbook Interaction',
  userAccountInteraction: 'User Account Interaction',
  videoInteraction: 'Video Interaction',
} as const;

export const EVENT_TYPES = {
  page: 'page',
  track: 'track',
  identify: 'identify',
} as const;

export const PARAMETERS = {
  addListing: 'add listing',
  allThumbnails: 'all thumbnails',
  amenities: 'amenities',
  applyFilter: 'apply filter',
  autofocusToggled: 'autofocus toggled',
  brandGallery: 'brand gallery',
  breadcrumb: 'breadcrumb',
  brochure: 'brochure',
  buildingInfo: 'building info',
  buildingPage: 'building page',
  buildingPageGallery: 'building page gallery',
  buildingThumbnail: 'building thumbnail',
  buildings: 'buildings',
  buildingVideo: 'building video',
  bulkDownload: 'bulk download',
  card: 'card',
  ceilingHeight: 'ceiling height',
  clearAllFilters: 'clear all filters',
  clickAllListingsTab: 'click all listings tab',
  clickDirectListingsTab: 'click direct listings tab',
  clickFloorplan: 'click floorplan',
  clickImagesTab: 'click images tab',
  clickSavedBuilding: 'click saved building',
  clickThreeDTourThumbnail: 'click 3D tour thumbnail',
  clickThumbnail: 'click thumbnail',
  clickVideoThumbnail: 'click video thumbnail',
  clickVirtualToursTab: 'click virtual tours tab',
  closeAllFilters: 'close filters',
  closeLightbox: 'close lightbox',
  closeListingPreview: 'close listing preview',
  closeMap: 'close map',
  closeModal: 'close modal',
  contactDropdown: 'contact dropdown',
  contactInfoCta: 'contact info cta',
  contactRevealContact: 'reveal contact',
  contactSection: 'contact section',
  contactSelectContact: 'select contact',
  contactShowAllContact: 'show all contact',
  copyUrl: 'copy url',
  createEntry: 'create entry',
  createTourbook: 'create tourbook',
  customAreaSearch: 'custom area search',
  customShareSent: 'custom share sent',
  decluster: 'decluster',
  deleteExternalListing: 'delete external listing',
  deleteListing: 'delete listing',
  deleteTourbook: 'delete tourbook',
  doubleClick: 'double click',
  download: 'download',
  downloadBrochure: 'download brochure',
  downloadFloorPlan: 'download floor plan',
  downloadImage: 'download image',
  downloadTourbook: 'download tourbook',
  downloadVideo: 'download video',
  duplicateAnotherTourbook: 'duplicate another tourbook',
  duplicateMyTourbook: 'duplicate my tourbook',
  editExternalListing: 'edit external listing',
  editTourbook: 'edit tourbook',
  emailNotificationsPage: 'email notifications page',
  external: 'external',
  externalListingLightboxGallery: 'external listing lightbox gallery',
  fiftyPercentPlayed: '50 percent played',
  finishPolygon: 'finish polygon',
  firstToMarket: 'first to market',
  firstToMarketCarousel: 'first to market carousel',
  firstToMarketSearchLink: 'first to market search link',
  homepage: 'homepage',
  hundredPercentPlayed: '100 percent played',
  inquiry: {
    error: 'inquiry error',
    resend: 'resend inquiry',
    send: 'send inquiry',
    general: 'general',
  },
  inquiryForm: 'inquiry form',
  inquiryPage: 'inquiry page',
  keywords: 'keywords',
  landlord: 'landlord',
  landlordName: 'landlord name',
  landlordPage: 'brand page',
  leaseType: 'lease type',
  lightboxGallery: 'lightbox gallery',
  listingAreasGallery: 'listing areas gallery',
  listingAreasLightboxGallery: 'listing areas lightbox gallery',
  listingCard: 'listing card',
  listingGallery: 'listing gallery',
  listingPage: 'listing page',
  listingPreview: 'listing preview',
  location: 'location',
  manageTourbooksPage: 'manage tourbooks page',
  map: 'map',
  mapEntryClickToScroll: 'click to scroll to entry',
  market: 'market',
  marketDropdown: 'market dropdown',
  marketMyTourbooksPage: 'market - my tourbooks page',
  marketTourbookExternalListingPage: 'market - tourbook external listing page',
  marketTourbookPage: 'market - tourbook page',
  marketTourbookAnalyticsPage: 'market - tourbook analytics page',
  navIcon: 'nav icon',
  navigation: 'nav',
  navListItem: 'nav list item',
  onMarketListings: 'on market listings',
  open3DTour: 'open 3d tour',
  openAllFilters: 'open all filters',
  openDropdown: 'open dropdown',
  openFilter: 'open filter',
  openLightbox: 'open lightbox',
  openListingPreview: 'open listing preview',
  openMap: 'open map',
  openModal: 'open modal',
  paginate: 'paginate',
  paginationBar: 'pagination bar',
  paywallPrompt: 'paywall prompt',
  play3DTour: 'play 3d tour',
  playVideo: 'play video',
  possession: 'possession',
  price: 'price',
  professionalInfoPage: 'professional info page',
  profilePage: 'profile page',
  quickInquiry: {
    defaultMessage: 'save default message',
    inquire: 'inquire',
    oneClick: 'one-click',
    quick: 'quick',
  },
  confirmDeleteSavedSearch: 'confirm delete saved search',
  deleteSavedSearch: 'delete saved search',
  editSavedSearch: 'edit saved search',
  openSavedSearchDropdown: 'open saved search dropdown',
  removeFilter: 'remove filter',
  reorderTourbookListing: 'reorder tourbook listing',
  reorderTourbookListingsEnd: 'end reordering tourbook listings',
  reorderTourbookListingsStart: 'begin reordering tourbook listings',
  retryDownload: 'retry download',
  retryDuplicateAnotherTourbook: 'retry duplicate another tourbook',
  retryDuplicateMyTourbook: 'retry duplicate my tourbook',
  save: 'save',
  saveChanges: 'save changes',
  saveChangesNew: 'save changes new',
  saveChangesUpdate: 'save changes update',
  savedBuildingOwnersPage: 'saved building owners page',
  savedBuildingsPage: 'saved buildings page',
  savedItem: 'saved item',
  savedListingsPage: 'saved listings page',
  savedSearchPage: 'saved searches page',
  saveItem: 'save item',
  saveRentOverride: 'save rent override',
  saveSearch: 'save search',
  saveSearchName: 'save search name',
  searchBar: 'search bar',
  searchButton: 'search button',
  searchCriteriaBar: 'search criteria bar',
  searchModule: 'search module',
  searchResultsPage: 'search results page',
  seenTourbookExplanation: 'see tourbook explanation',
  select: 'select',
  selectEmail: 'select email',
  selectLeaseTerm: 'select lease term',
  selectListing: 'select listing',
  selectSavedSearch: 'select saved search',
  seventyFivePercentPlayed: '75 percent played',
  shareError: 'share error',
  shareForm: 'share form',
  shareSent: 'share sent',
  sidebar: 'sidebar',
  signUpPage: 'signup page',
  siteVisitorInquiryForm: 'site visitor inquiry form',
  size: 'size',
  space3DTour: 'space 3d tour',
  spaceCondition: 'space condition',
  spaceVideo: 'space video',
  spotlightTour: 'spotlight tour',
  startPolygon: 'start polygon',
  table: 'table',
  tableOfContents: 'table of contents',
  tableOfContentsCollapse: 'collapse table of contents',
  tableOfContentsDropdown: 'table of contents dropdown',
  tableOfContentsExpand: 'expand table of contents',
  terms: 'terms',
  threeDTourImpression: 'impression',
  toast: 'toast',
  tourbook: 'tourbook',
  tourbookContactInfoPage: 'tourbook contact info page',
  tourbookExternalListingGallery: 'tourbook external listing gallery',
  tourbookExternalListingPage: 'tourbook external listing page',
  tourbookListing: 'tourbook listing',
  tourbookListingGallery: 'tourbook listing gallery',
  tourbookOverlay: 'tourbook overlay',
  tourbookPage: 'tourbook page',
  tourbookAnalyticsPage: 'tourbook analytics page',
  truvaHomepage: 'marketplace.vts.com',
  truvaLogo: 'vts marketplace logo',
  turnOffAlerts: 'turn off alerts',
  turnOnAlerts: 'turn on alerts',
  twentyFivePercentPlayed: '25 percent played',
  unsaveItem: 'unsave item',
  updateInquirySettings: 'update inquiry settings',
  updateProfessionalInfo: 'update professional info',
  userPolicyAgree: 'user policy agree',
  userPolicyCancel: 'user policy cancel',
  valueProp: 'value prop',
  videoImpression: 'impression',
  viewAnalytics: 'view analytics',
  view3DTour: 'view 3D tour',
  viewBuilding: 'view building button',
  viewFloorPlan: 'view floor plan',
  viewListing: 'view listing',
  viewMap: 'view map',
  viewPortfolio: 'view portfolio button',
  viewThumbnailData: 'view thumbnail data',
  viewTourbookExternalListing: 'view tourbook external listing',
  viewTourbookListing: 'view tourbook listing',
  viewVideo: 'view video',
  zoom: 'zoom',
} as const;
