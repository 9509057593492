import { CurrentUser, Action } from '@root/types';
import { RECEIVE_CURRENT_USER } from '@store/entities';

export type CurrentUserStoreState = CurrentUser | null;
const defaultState = null;

export default function currentUserReducer(
  state: CurrentUserStoreState = defaultState,
  action: Action,
) {
  switch (action.type) {
    case RECEIVE_CURRENT_USER:
      return action.payload.user;
    case 'RECEIVE_UPDATED_CURRENT_USER':
      return action.payload.user;
    default:
      return state;
  }
}
