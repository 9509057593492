import { Action, Building, BuildingMapPoint, BuildingListing } from '@root/types';

export type BuildingsStoreState = {
  byId: { [id: string]: Building };
  searchResults: BuildingMapPoint[];
  listingsBySlug: { [slug: string]: BuildingListing[] | undefined };
};

export const defaultState: BuildingsStoreState = {
  byId: {},
  searchResults: [],
  listingsBySlug: {},
};

export default function buildingsReducer(
  state: BuildingsStoreState = defaultState,
  action: Action,
) {
  switch (action.type) {
    case 'LISTING_SEARCH_PAGE_SERVER_SIDE_RENDER_INITIAL_LOAD': {
      return { ...state, searchResults: action.payload.buildings };
    }
    case 'RECEIVE_SEARCH_BUILDINGS': {
      const { buildings } = action.payload;
      return { ...state, searchResults: buildings };
    }
    case 'BUILDING_PAGE_SERVER_SIDE_RENDER_INITIAL_LOAD': {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: action.payload,
        },
      };
    }
    default:
      return state;
  }
}
