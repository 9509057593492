import React, { forwardRef } from 'react';
import cn from 'classnames';
import Bugsnag from '@shared/bugsnagClient';
import {
  Analytics,
  ArrowsUpDown,
  BackArrow,
  BrochureDownload,
  BuildingsMultiple,
  Camera,
  Checkmark,
  ChevronLeft,
  ChevronLeftShadow,
  ChevronLeftSlate,
  ChevronRight,
  ChevronRightShadow,
  ChevronRightSlate,
  Close,
  CloseCircle,
  CompanyLogo,
  ContactInfo,
  Desktop,
  Download,
  Download2,
  DownloadMultiple,
  DragHandle,
  DropdownDown,
  DropdownUp,
  Duplicate,
  Edit,
  Email,
  EmptyBuilding,
  EnvelopeFilled,
  EnvelopeOutline,
  ExclamationCircle,
  Eye,
  Filters,
  FirstToMarketLabel,
  FlexibleSizeArea,
  FloorPlan,
  GripDots,
  Heart,
  HeartOutline,
  Info,
  LangSelectionGBFlag,
  LangSelectionUSFlag,
  Question,
  Leeds,
  Lightbulb,
  Link,
  LinkOut,
  List,
  Location,
  Lock,
  MagnifyingGlass,
  MagnifyingGlassHeart,
  MapRefresh,
  MediaFile,
  Minus,
  Move,
  MultiPage,
  Overflow,
  Paintbrush,
  PdfFilled,
  PersonCircle,
  Phone,
  PinDrop,
  Plus,
  Reset,
  SetupOneClick,
  Share,
  SinglePage,
  ThreeDTour,
  Tourbook,
  TourbookFilled,
  Trash,
  TruvaT,
  Union,
  Upload,
  VtsLogoBlack,
  VtsLogoIndigo,
  WiredScore,
  WiredScoreCertified,
  WiredScoreGold,
  WiredScorePlatinum,
  WiredScoreSilver,
  UploadOutlined,
  MenuOutlined,
  LoadingOutlined,
  Person,
  Transparent,
} from './icons';

/*
 * PLEASE HELP US KEEP THIS MANAGABLE:
 * 1) use descriptive names, no whatever1 and whatever2
 * 2) take the fills off the svg and color w/ CSS
 * do not make extra versions of the same icon in different colors
 * 3) name all antd icons clearly, i.e. loadingOutlined
 * 4) try to keep this list in _some_ order
 */

// all icons in the below list are automatically in the design-system
export const componentMappings = {
  '3d-tour': { Component: ThreeDTour },
  analytics: { Component: Analytics },
  arrowsUpDown: { Component: ArrowsUpDown },
  'back-arrow': { Component: BackArrow },
  'brochure-download': { Component: BrochureDownload },
  'buildings-multiple': { Component: BuildingsMultiple },
  camera: { Component: Camera },
  checkmark: { Component: Checkmark },
  'chevron-left': { Component: ChevronLeft },
  'chevron-up': { Component: DropdownUp },
  'chevron-down': { Component: DropdownDown },
  'chevron-left-shadow': { Component: ChevronLeftShadow },
  'chevron-left-slate': { Component: ChevronLeftSlate },
  'chevron-right': { Component: ChevronRight },
  'chevron-right-shadow': { Component: ChevronRightShadow },
  'chevron-right-slate': { Component: ChevronRightSlate },
  close: { Component: Close },
  'close-circle': { Component: CloseCircle },
  'company-logo': { Component: CompanyLogo },
  'contact-info': { Component: ContactInfo },
  desktop: { Component: Desktop },
  download: { Component: Download },
  download2: { Component: Download2 },
  'download-multiple': { Component: DownloadMultiple },
  dragHandle: { Component: DragHandle },
  'dropdown-down': { Component: DropdownDown },
  'dropdown-up': { Component: DropdownUp },
  duplicate: { Component: Duplicate },
  edit: { Component: Edit },
  email: { Component: Email },
  emptyBuilding: { Component: EmptyBuilding },
  envelopeFilled: { Component: EnvelopeFilled },
  envelopeOutline: { Component: EnvelopeOutline },
  'exclamation-circle': { Component: ExclamationCircle },
  eye: { Component: Eye },
  filters: { Component: Filters },
  'first-to-market': { Component: FirstToMarketLabel },
  'flexible-size-area': { Component: FlexibleSizeArea },
  'floor-plan': { Component: FloorPlan },
  'grip-dots': { Component: GripDots },
  heart: { Component: Heart },
  'heart-outline': { Component: HeartOutline },
  info: { Component: Info },
  'lang-selection-flag-en-gb': { Component: LangSelectionGBFlag },
  'lang-selection-flag-en-us': { Component: LangSelectionUSFlag },
  leeds: { Component: Leeds },
  question: { Component: Question },
  lightbulb: { Component: Lightbulb },
  link: { Component: Link },
  linkOut: { Component: LinkOut },
  list: { Component: List },
  loadingOutlined: {
    Component: LoadingOutlined,
    style: { animation: 'loadingCircle 1s linear infinite' },
  },
  location: { Component: Location },
  lock: { Component: Lock },
  'magnifying-glass': { Component: MagnifyingGlass },
  'magnifying-glass-heart': { Component: MagnifyingGlassHeart },
  'map-refresh': { Component: MapRefresh },
  'media-file': { Component: MediaFile },
  menuOutlined: { Component: MenuOutlined },
  minus: { Component: Minus },
  move: { Component: Move },
  'multi-page': { Component: MultiPage },
  overflow: { Component: Overflow },
  paintbrush: { Component: Paintbrush },
  pdfFilled: { Component: PdfFilled },
  'person-circle': { Component: PersonCircle },
  person: { Component: Person },
  phone: { Component: Phone },
  'pin-drop': { Component: PinDrop },
  plus: { Component: Plus },
  reset: { Component: Reset },
  'setup-one-click': { Component: SetupOneClick },
  share: { Component: Share },
  'single-page': { Component: SinglePage },
  tourbook: { Component: Tourbook },
  'tourbook-filled': { Component: TourbookFilled },
  trash: { Component: Trash },
  'truva-t': { Component: TruvaT },
  union: { Component: Union },
  upload: { Component: Upload },
  uploadOutlined: { Component: UploadOutlined },
  'vts-logo-black': { Component: VtsLogoBlack },
  'vts-logo-indigo': { Component: VtsLogoIndigo },
  wiredScore: { Component: WiredScore },
  wiredScoreCertified: { Component: WiredScoreCertified },
  wiredScoreGold: { Component: WiredScoreGold },
  wiredScorePlatinum: { Component: WiredScorePlatinum },
  wiredScoreSilver: { Component: WiredScoreSilver },
  transparent: { Component: Transparent },
} as const;

export type ValidIconTypes = keyof typeof componentMappings;

type Props = {
  type: ValidIconTypes;
  title?: string;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  'data-testid'?: string;
};

const CustomIcon = forwardRef<HTMLSpanElement, Props>(({ type, ...passProps }, ref) => {
  if (componentMappings[type]) {
    const { Component } = componentMappings[type];
    // @ts-expect-error
    const componentStyle = (componentMappings[type].style as React.CSSProperties | undefined) || {};

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <span
        ref={ref}
        onClick={passProps.onClick}
        title={passProps.title}
        className={cn('inline-block', 'text-inherit', 'text-center', passProps.className)}
        role="img"
        data-testid={passProps['data-testid'] || type}
        style={{
          lineHeight: 0,
          verticalAlign: '-0.125em',
          textRendering: 'optimizeLegibility',
          WebkitFontSmoothing: 'antialiased',
          ...componentStyle,
        }}
      >
        <Component fill="currentColor" />
      </span>
    );
  }

  Bugsnag.notify(`Unsupported icon type: ${type}`);
  return null;
});

export default CustomIcon;
