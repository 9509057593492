import { BackendEnums, LandlordAnalyticsInformation, Building, Listing } from '@root/types';
import { Page } from '@store/reducers/pageReducer';
import tourbookExternalListingActions from '@store/actions/tourbookExternalListingPage';
import {
  LISTING_SEARCH_MAP_MOVED,
  LISTING_SEARCH_MAP_RESET,
  LISTING_SEARCH_DRAW_START,
  LISTING_SEARCH_DRAW_END,
  LISTING_SEARCH_POLYGON_COMPLETE,
  LISTING_SEARCH_SET_DRAWN_POLYGONS,
} from '../entities';
import uiActions from './ui';

type PageInfoPayload = { type: Page; pageArguments?: any };
type SetPageAction = {
  type: 'SET_PAGE';
  payload: PageInfoPayload;
};
const setPage = (pageInfo: PageInfoPayload): SetPageAction => ({
  type: 'SET_PAGE',
  payload: pageInfo,
});

type SetEnvironmentAction = { type: 'SET_ENVIRONMENT'; payload: string };
const setEnvironment = (env: string): SetEnvironmentAction => ({
  type: 'SET_ENVIRONMENT',
  payload: env,
});
const setVideoEmbedPage = pageArguments => setPage({ type: 'VIDEO_EMBED', pageArguments });

const setListingPage = (listing: Listing, isPreview: boolean): SetPageAction =>
  setPage({
    type: 'LISTING',
    pageArguments: {
      ...listing.analyticsInformation,
      buildingEmployerId: listing.building.employerId,
      listingAgentsEmails: listing.listingAgents?.map(({ email }) => email),
      listingPreview: isPreview,
    },
  });

const setAccountSettingsPage = () => setPage({ type: 'ACCOUNT_SETTINGS' });
const setSharedListingPage = id => setPage({ type: 'LISTING_SHARE', pageArguments: { id } });
const setHomepage = () => setPage({ type: 'HOMEPAGE' });

const setSavedBuildingsPage = () => setPage({ type: 'SAVED_BUILDINGS' });
const setSavedListingsPage = () => setPage({ type: 'SAVED_LISTINGS' });
const setSavedBuildingOwnersPage = () => setPage({ type: 'SAVED_BUILDING_OWNERS' });
const setSavedSearchesPage = () => setPage({ type: 'SAVED_SEARCHES' });

const setBuildingPage = (building: Building, isPreview: boolean) =>
  setPage({
    type: 'BUILDING',
    pageArguments: { ...building.analyticsInformation, buildingPreview: isPreview },
  });

const setListingSearchPage = ({ pageNumber }) =>
  setPage({ type: 'LISTING_SEARCH', pageArguments: { pageNumber } });

const setLandlordPage = (
  analyticsInformation: LandlordAnalyticsInformation,
  landlordPreview: boolean,
) => setPage({ type: 'LANDLORD', pageArguments: { ...analyticsInformation, landlordPreview } });

const setTourbookPage = (id: string, isShare: boolean): SetPageAction =>
  setPage({ type: 'TOURBOOK', pageArguments: { id, isShare } });

const setTourbookAnalyticsPage = (id: string): SetPageAction =>
  setPage({ type: 'TOURBOOK_ANALYTICS', pageArguments: { id } });

const setManageTourbookPage = (): SetPageAction =>
  setPage({ type: 'MANAGE_TOURBOOKS', pageArguments: {} });

const setNotFoundPage = (): SetPageAction => setPage({ type: 'NOT_FOUND' });

const setTourbookExternalListingPage = (tourbookId: string): SetPageAction =>
  setPage({ type: 'TOURBOOK_EXTERNAL_LISTING', pageArguments: { tourbookId } });

const setTourbookEditExternalListingPage = ({ tourbookId, externalListingId }): SetPageAction =>
  setPage({
    type: 'TOURBOOK_EDIT_EXTERNAL_LISTING',
    pageArguments: { tourbookId, externalListingId },
  });

type SetEnumsAction = { type: 'SET_ENUMS'; payload: BackendEnums };
const setEnums = (enums: BackendEnums): SetEnumsAction => ({
  type: 'SET_ENUMS',
  payload: enums,
});

const mapDrawStart = { type: LISTING_SEARCH_DRAW_START } as const;
type MapDrawStart = typeof mapDrawStart;

const mapDrawEnd = { type: LISTING_SEARCH_DRAW_END } as const;
type MapDrawEnd = typeof mapDrawEnd;

const mapDrawingPolygonComplete = { type: LISTING_SEARCH_POLYGON_COMPLETE } as const;
type MapDrawingPolygonComplete = typeof mapDrawingPolygonComplete;

type SetDrawnPolygonsAction = { type: 'LISTING_SEARCH_SET_DRAWN_POLYGONS'; payload: any[] };
const setDrawnPolygons = (payload: any[]): SetDrawnPolygonsAction => ({
  type: LISTING_SEARCH_SET_DRAWN_POLYGONS,
  payload,
});

const mapMoved = { type: LISTING_SEARCH_MAP_MOVED } as const;
type MapMoved = typeof mapMoved;
const mapReset = { type: LISTING_SEARCH_MAP_RESET } as const;
type MapReset = typeof mapReset;

const toggleVisualizingAnalytics = { type: 'VISUALIZING_ANALYTICS_TOGGLED' };
type ToggleVisualAnalytics = { type: 'VISUALIZING_ANALYTICS_TOGGLED' };

export default {
  setListingPage,
  setBuildingPage,
  setListingSearchPage,
  setSharedListingPage,
  setLandlordPage,
  setVideoEmbedPage,
  setTourbookPage,
  setTourbookAnalyticsPage,
  setManageTourbookPage,
  setSavedBuildingsPage,
  setSavedListingsPage,
  setSavedBuildingOwnersPage,
  setSavedSearchesPage,
  setAccountSettingsPage,
  setNotFoundPage,
  setTourbookEditExternalListingPage,
  setHomepage,
  setEnums,
  setTourbookExternalListingPage,
  mapDrawStart,
  mapDrawEnd,
  mapDrawingPolygonComplete,
  mapReset,
  mapMoved,
  setDrawnPolygons,
  toggleVisualizingAnalytics,
  setEnvironment,
  ...uiActions,
  ...tourbookExternalListingActions,
};

export type Actions =
  | ToggleVisualAnalytics
  | SetEnvironmentAction
  | SetEnumsAction
  | SetPageAction
  | MapDrawStart
  | MapDrawEnd
  | MapReset
  | MapMoved
  | MapDrawingPolygonComplete
  | SetDrawnPolygonsAction;
