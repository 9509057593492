import { BackendEnums, Action } from '@root/types';

const defaultState = { markets: [], marketMapCenters: {} };
export type EnumsStoreState = BackendEnums;

export default function enumsReducer(state: EnumsStoreState = defaultState, action: Action) {
  switch (action.type) {
    case 'SET_ENUMS':
      return action.payload;
    default:
      return state;
  }
}
