import redirectToLoginIfUnauthorized from '@shared/redirectToLoginIfUnauthorized';

const getCsrfToken = () => {
  const tokenElement = document.head.querySelector('[name="csrf-token"]');

  return tokenElement && tokenElement.content;
};

export const csrfTokenHeader = () => ({
  'X-CSRF-TOKEN': getCsrfToken(),
});

const defaultHeaders = (useJson = true) => ({
  ...csrfTokenHeader(),
  ...(useJson ? { 'Content-Type': 'application/json' } : {}),
});

const fetch = (url, options) => window.fetch(url, options);

const getUseJson = options =>
  Object.prototype.hasOwnProperty.call(options, 'useJson') ? options.useJson : true;

const API = {
  fetch,
  post: async (url, body, options = { useJson: true }) => {
    const useJson = getUseJson(options);
    const response = await fetch(url, {
      ...options,
      method: 'POST',
      body: useJson ? JSON.stringify(body) : body,
      headers: { ...defaultHeaders(useJson), ...options.headers },
    });

    redirectToLoginIfUnauthorized(response);
    return response;
  },
  put: async (url, body, options = { useJson: true }) => {
    const useJson = getUseJson(options);
    const response = fetch(url, {
      ...options,
      method: 'PUT',
      body: useJson ? JSON.stringify(body) : body,
      headers: { ...defaultHeaders(useJson), ...options.headers },
    });

    redirectToLoginIfUnauthorized(response);
    return response;
  },
  delete: async (url, body, options = {}) => {
    const response = fetch(url, {
      ...options,
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: { ...defaultHeaders(), ...options.headers },
    });

    redirectToLoginIfUnauthorized(response);
    return response;
  },
};

export default API;
