import units from './units';
import { getPeriod } from './periods';

type args = {
  area: 'SquareFoot' | 'SquareMeter';
  period: 'month' | 'year';
  abbreviatedPeriod: boolean | undefined | null;
};

export default ({ area, period, abbreviatedPeriod = false }: args) => {
  const formattedAreaUnit = units[area];
  const formattedTimePeriod = getPeriod(period, abbreviatedPeriod);

  return `${formattedAreaUnit} / ${formattedTimePeriod}`;
};
