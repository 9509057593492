export const ENUMS = 'enums';
export const LISTING_SEARCH_LOADING = 'LISTING_SEARCH_LOADING';
export const LISTING_SEARCH_MAP_MOVED = 'LISTING_SEARCH_MAP_MOVED';
export const LISTING_SEARCH_MAP_RESET = 'LISTING_SEARCH_MAP_RESET';
export const LISTING_SEARCH_DRAW_START = 'LISTING_SEARCH_DRAW_START';
export const LISTING_SEARCH_DRAW_END = 'LISTING_SEARCH_DRAW_END';
export const LISTING_SEARCH_POLYGON_COMPLETE = 'LISTING_SEARCH_POLYGON_COMPLETE';
export const LISTING_SEARCH_SET_DRAWN_POLYGONS = 'LISTING_SEARCH_SET_DRAWN_POLYGONS';
export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';
export const RECEIVE_NO_USER = 'RECEIVE_NO_USER';
export const TOURBOOKS_LOADED = 'TOURBOOKS_LOADED';
