import tourbookExternalListingPageLoad from './tourbookExternalListingPageLoad';
import editExternalListingPageLoad, {
  receiveExternalListing,
  Actions as EditExteralListingsActions,
} from './editExternalListingPageLoad';
import analyticsActions from './analyticsActions';

export type Actions = EditExteralListingsActions;

export default {
  ...analyticsActions,
  tourbookExternalListingPageLoad,
  editExternalListingPageLoad,
  receiveExternalListing,
};
