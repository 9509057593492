import api from '@shared/api';
import routes from '@root/routes';
import { CurrentUser, IterableUserAttributes, UserAnalyticsInformation } from '@root/types';
import { RECEIVE_CURRENT_USER, RECEIVE_NO_USER } from '@store/entities';

const requestCurrentUser = {
  type: 'REQUEST_CURRENT_USER',
  payload: {},
} as const;
type RequestCurrentUserAction = typeof requestCurrentUser;

type ReceiveCurrentUserAction = {
  type: typeof RECEIVE_CURRENT_USER;
  payload: {
    user: CurrentUser;
    meta: {
      analytics: {
        forIdentification: true;
        identifier: string;
        user: UserAnalyticsInformation & Partial<IterableUserAttributes>;
      };
    };
  };
};

export const receiveCurrentUser = (
  user: CurrentUser,
  iterableUserAttributes: IterableUserAttributes = {},
): ReceiveCurrentUserAction => ({
  type: RECEIVE_CURRENT_USER,
  payload: {
    user,
    meta: {
      analytics: {
        forIdentification: true,
        identifier: user.email,
        user: {
          ...user.analyticsInformation,
          ...iterableUserAttributes,
        },
      },
    },
  },
});

type ReceiveNoUserAction = {
  type: typeof RECEIVE_NO_USER;
  payload: {
    meta: {
      analytics: {
        forIdentification: true;
        user: null | Partial<IterableUserAttributes>;
      };
    };
  };
};
export const receiveNoUser = (
  iterableUserAttributes?: IterableUserAttributes,
): ReceiveNoUserAction => ({
  type: RECEIVE_NO_USER,
  payload: {
    meta: {
      analytics: {
        forIdentification: true,
        user: iterableUserAttributes || null,
      },
    },
  },
});

const handleErrors = async response => {
  if (response.ok) return response;

  throw Error();
};

const fetchCurrentUser = (iterableUserAttributes?: IterableUserAttributes) => async dispatch => {
  dispatch(requestCurrentUser);
  api
    .fetch(routes.api.currentUser)
    .then(handleErrors)
    .then(async response => {
      const user: CurrentUser = await response.json();
      dispatch(receiveCurrentUser(user, iterableUserAttributes));
    })
    .catch(() => {
      dispatch(receiveNoUser(iterableUserAttributes));
    });
};

export default fetchCurrentUser;
export type Actions = ReceiveCurrentUserAction | ReceiveNoUserAction | RequestCurrentUserAction;
