/* eslint-disable react/jsx-no-literals */
import renderingInServer from '@shared/renderingInServer';
import Notification from '@components/shared/Notification';
import { isFunction } from 'lodash';
import bugsnag from '@shared/bugsnagClient';
import { windows, android, linux, macos, ios, formfactor } from 'platform-detect';

const noAccessToAnalyticsWithCheck = (environment: string) => {
  const noAccess = renderingInServer() || !window.analytics;
  if (noAccess && environment === 'production') {
    bugsnag.notify('No Access to Analytics in Production!');
  }

  return noAccess;
};

const determineUserOS = () => {
  if (windows) return 'Windows';
  if (macos) return 'Mac OS X';
  if (ios) return 'iOS';
  if (android) return 'Android';
  if (linux) return 'Linux';
  return 'Unknown';
};

const titleText = (event: string, text: string) => {
  if (event === 'page') {
    return `Page load: ${text}`;
  }
  if (event === 'identify') {
    return `User with email: ${text} loaded`;
  }
  return text;
};

const notify = ({ event, title, params }) => {
  const alphabetizedParams = Object.keys(params)
    .sort()
    .reduce((acc, current) => {
      acc[current] = params[current];
      return acc;
    }, {});
  Notification.info({
    duration: 10,
    placement: 'bottomRight',
    title: titleText(event, title),
    text: (
      <pre
        style={{ fontSize: 10, lineHeight: '12px', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
      >
        params: {JSON.stringify(alphabetizedParams, null, 2)}
      </pre>
    ),
    iframe: {
      displayNotificationInIframe: true,
    },
  });
};

export const identify = (
  userEmail,
  params,
  { visualize, environment } = { visualize: false, environment: 'development' },
) => {
  if (visualize) {
    notify({ event: 'identify', title: userEmail, params });
  }
  if (noAccessToAnalyticsWithCheck(environment)) return;

  window.analytics!.identify(userEmail, {
    operatingSystem: determineUserOS(),
    deviceType: formfactor,
    ...params,
  });
};

export const track = (
  event,
  params,
  { visualize, environment } = { visualize: false, environment: 'development' },
) => {
  if (visualize) {
    notify({ event: 'track', title: event, params });
  }
  if (noAccessToAnalyticsWithCheck(environment)) return;

  window.analytics!.track(event, params);
};

export const page = (
  pageName,
  params,
  { visualize, environment } = { visualize: false, environment: 'development' },
) => {
  if (visualize) {
    notify({ event: 'page', title: pageName, params });
  }
  if (noAccessToAnalyticsWithCheck(environment)) return;

  window.analytics!.page(pageName, params);
};

export const anonymousId = () => {
  if (renderingInServer() || !window.analytics) return undefined;

  if (!isFunction(window.analytics!.user)) return undefined;

  return window.analytics!.user().anonymousId();
};
